import React, { useRef } from 'react';
import { Card } from '../main/catalog.component';
import './jobsearch.style.css';
import { Toast } from 'primereact/toast';
import { store } from '../../redux/store';
import { SCROLL_TO_COMPONENT } from '../../redux/types';
// import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { Virtuoso } from 'react-virtuoso';
// import HeightPreservingItem from '../main/heightpreservingitem.component';

const SearchResult = ({ data, style }) => {
  const toast = useRef(null);
  const renderedWorkcenters = useSelector(
    (state) => state.columnInfo.workcenter
  );
  const allWorkCenters = useSelector((state) => state.workcenters);

  const onClickHandler = (target) => {
    //--- Check if target workcenter is rendered ---
    let isRendered =
      target.status.toUpperCase() === 'UNSCHEDULED' ||
      renderedWorkcenters.some((obj) => obj.workcenter === target.workcenter);

    if (isRendered) {
      store.dispatch({
        type: SCROLL_TO_COMPONENT,
        scrollToComponent: {
          status: target.status,
          workcenter:
            target.status === 'Unscheduled' ? 'Unscheduled' : target.workcenter,
          index: target.columnIndex,
          itemId: target.id,
        },
      });
    } else {
      let detailMessageHead = '';
      let detailMessageTail = '';
      let summary = '';
      const wcName = allWorkCenters.find(
        (obj) => obj.workcenter === target.workcenter
      )?.workcenter_name;
      detailMessageHead = `Selected item is located in`;
      detailMessageTail = wcName
        ? ` ${wcName} (code:${target.workcenter}) (index:${target.row_no + 1})`
        : ` ${target.workcenter} - hidden`;
      summary = 'Please check filter options';

      const detailMessage = (
        <>
          {detailMessageHead}
          <br />
          <span style={{ fontWeight: '500', color: 'var(--danger)' }}>
            {detailMessageTail}
          </span>
        </>
      );

      toast.current.show({
        severity: 'warn',
        summary: summary,
        detail: detailMessage,
        life: 5000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />

      <div
        className="search-result__container"
        style={{ padding: '0 0.3rem', height: '100%', ...style }}
      >
        {Array.isArray(data) &&
          data.map((obj, index) => (
            <Card
              id={index}
              key={index}
              data={obj}
              originColumnName="search-result"
              onClick={() => onClickHandler(obj)}
            />
          ))}
      </div>
    </>
  );
};

export default SearchResult;
