import pjson from '../../../package.json';

const FooterComponent = ({ color }) => (
  <div
    style={{
      height: '30px',
      textAlign: 'center',
      padding: '5px',
      color: color || 'white',
      fontSize: '14px',
      backgroundColor: 'black',
    }}
  >
    <p>
      Copyright &copy; 2022 Alpaca System All | All rights reserved.{' '}
      <b>Version</b> {pjson.version}
    </p>
  </div>
);

export default FooterComponent;
