import { createStore, AnyAction } from 'redux';
import { AppState, AppAction } from './types';
import { reducer, initialState } from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; //

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'scrollToComponent',
    'columnInfo',
    'updateItemFlag',
    'workcenters',
  ],
};

const persistedReducer = persistReducer<any, AnyAction>(persistConfig, reducer);

export const store = createStore<AppState, AppAction, void, void>(
  persistedReducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);
