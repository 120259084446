import axios from 'axios';
import { notification } from 'antd';
import { CONST_API_URL } from '../../appconfig';

export function ValidateTokenService(token) {
  return axios
    .post(`${CONST_API_URL}/auth/validateToken`, { token })
    .then(({ data }) => {
      return !data;
    })
    .catch((error) => `ValidateTokenService: ${error}`);
}

export function UpdatePasswordService(password, token) {
  return axios
    .post(`${CONST_API_URL}/auth/updateUserPass`, { password, token })
    .then(({ data: result }) => {
      if (result === false) {
        notification.error({
          message: 'Password Reset Failed!',
        });
      } else {
        notification.success({
          message: 'Password Reset Success!',
        });
      }
      return;
    })
    .catch((error) => `UpdatePasswordService: ${error}`);
}
