import React from 'react';
import axios from 'axios';
import './mainpage.style.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import SearchFilters from '.././searchbar/classicsearch.component';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column as PrimeColumn } from 'primereact/column';
import { Spin, Row, Col, Divider } from 'antd';
import LockIcon from '@material-ui/icons/Lock';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import _ from 'lodash';
import { TwitterPicker } from 'react-color';
import moment from 'moment';
import SearchResult from '../searchbar/searchresult.component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DueDateIcon from '../../assets/Icons/DueDate.svg';
import EstimatedTimeIcon from '../../assets/Icons/Estimated_Time.svg';
import OperationNoIcon from '../../assets/Icons/OperationNo.svg';
import PaintBrushIcon from '../../assets/Icons/PaintBrush.svg';
import ProgressIcon from '../../assets/Icons/Progress.svg';
import QtyIcon from '../../assets/Icons/Quantity.svg';
import WorkCentreIcon from '../../assets/Icons/WorkCentre.svg';
import CustomerIcon from '../../assets/Icons/Customer.svg';
import MaterialIcon from '../../assets/Icons/Material.svg';
import { Virtuoso } from 'react-virtuoso';
import HeightPreservingItem from './heightpreservingitem.component';
import { store } from '../../redux/store';
import {
  SCROLL_TO_COMPONENT,
  UPDATE_SCHEDULED_ITEM,
  UPDATE_UNSCHEDULED_ITEM,
} from '../../redux/types';
import './virtualizedlist.styles.css';
import Disabled from '../disabled.component';
import { CONST_API_URL } from '../../appconfig';
import PostHistoryDialog from '../dialog/posthistorydialog.component';
import { isTimeLimitPassed } from '../../functions/timeFunctions';
import CardMovementHistoryDialog from '../dialog/cardmovementhistorydialog.component';
import OperationDialog from '../dialog/operationdialog.component';

let id = [0, 'nothing'];
let insertlocation;
let editing = false;
function handleDragOver(e) {
  e.preventDefault();
  e.stopPropagation();
}

const MAX_LAST_POST_TIME_HOURS = 4; //hours
const MAX_SETUP_TIME_HOURS = 0.5;
const MAX_RUN_TIME_PAUSED_HOURS = 0.5;

export class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      tags: [],
      displayColorPicker: false,
      color: {
        r: '255',
        g: '255',
        b: '255',
        a: '1',
      },
      lastPostStatus: false, //Highlight header and description in orange if job hasn't been posted to in the last 4 hours
      setupStatus: false, //Highlight header and description in yellow if setup time has exceeded the esimated time
    };
    this.tags = [];
    // console.log('TEST:', props);
  }

  componentDidMount() {
    const current = moment();
    let propsData;

    if (Array.isArray(this.props.data)) {
      propsData = this.props.data[0];

      const setupStatus = this.props.data.some(
        (item) =>
          (item.progress_status === 1 && // Setup running
            isTimeLimitPassed(
              item.progress_start_time || current,
              current,
              MAX_SETUP_TIME_HOURS
            )) ||
          (item.progress_status === 6 && // Setup paused
            isTimeLimitPassed(
              item.progress_end_time || current,
              current,
              MAX_SETUP_TIME_HOURS
            ))
      );

      const lastPostStatus = this.props.data.some(
        (item) =>
          (item.progress_status === 3 && // Job running
            isTimeLimitPassed(
              item.last_post_qty_time || item.progress_start_time || current,
              current,
              MAX_LAST_POST_TIME_HOURS
            )) ||
          (item.progress_status === 4 && // Job paused
            isTimeLimitPassed(
              item.progress_end_time || current,
              current,
              MAX_RUN_TIME_PAUSED_HOURS
            ))
      );

      this.setState({
        setupStatus: setupStatus,
        lastPostStatus: lastPostStatus,
      });
    } else {
      propsData = this.props.data;

      if (propsData?.progress_status === 1) {
        // Setup running
        this.setState({
          setupStatus: isTimeLimitPassed(
            propsData.progress_start_time || current,
            current,
            MAX_SETUP_TIME_HOURS
          ),
        });
      } else if (propsData?.progress_status === 6) {
        // Setup paused
        this.setState({
          setupStatus: isTimeLimitPassed(
            propsData.progress_end_time || current,
            current,
            MAX_SETUP_TIME_HOURS
          ),
        });
      } else if (propsData?.progress_status === 3) {
        // Job running
        this.setState({
          lastPostStatus: isTimeLimitPassed(
            propsData.last_post_qty_time ||
              propsData.progress_start_time ||
              current,
            current,
            MAX_LAST_POST_TIME_HOURS
          ),
        });
      } else if (propsData?.progress_status === 4) {
        // Job paused
        this.setState({
          lastPostStatus: isTimeLimitPassed(
            propsData.progress_end_time || current,
            current,
            MAX_RUN_TIME_PAUSED_HOURS
          ),
        });
      }
    }

    if (!!propsData.card_color) {
      this.setState({ color: this.hexToRgb(propsData.card_color) });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('card Component updated');
    let currPropData, prevPropData;

    if (!_.isEqual(this.props.data, prevProps.data)) {
      const current = moment();

      if (Array.isArray(this.props.data)) {
        currPropData = this.props.data[0];
        prevPropData = prevProps.data[0];

        const setupStatus = this.props.data.some(
          (item) =>
            (item.progress_status === 1 && // Setup running
              isTimeLimitPassed(
                item.progress_start_time || current,
                current,
                MAX_SETUP_TIME_HOURS
              )) ||
            (item.progress_status === 6 && // Setup paused
              isTimeLimitPassed(
                item.progress_end_time || current,
                current,
                MAX_SETUP_TIME_HOURS
              ))
        );

        const lastPostStatus = this.props.data.some(
          (item) =>
            (item.progress_status === 3 && // Job running
              isTimeLimitPassed(
                item.last_post_qty_time || item.progress_start_time || current,
                current,
                MAX_LAST_POST_TIME_HOURS
              )) ||
            (item.progress_status === 4 && // Job paused
              isTimeLimitPassed(
                item.progress_end_time || current,
                current,
                MAX_RUN_TIME_PAUSED_HOURS
              ))
        );

        this.setState({
          setupStatus: setupStatus,
          lastPostStatus: lastPostStatus,
        });
      } else {
        currPropData = this.props.data;
        prevPropData = prevProps.data;
        const current = moment();

        if (currPropData?.progress_status === 1) {
          // Setup running
          this.setState({
            setupStatus: isTimeLimitPassed(
              currPropData.progress_start_time || current,
              current,
              MAX_SETUP_TIME_HOURS
            ),
          });
        } else if (currPropData?.progress_status === 6) {
          // Setup paused
          this.setState({
            setupStatus: isTimeLimitPassed(
              currPropData.progress_end_time || current,
              current,
              MAX_SETUP_TIME_HOURS
            ),
          });
        } else if (currPropData?.progress_status === 3) {
          // Job running
          this.setState({
            lastPostStatus: isTimeLimitPassed(
              currPropData.last_post_qty_time ||
                currPropData.progress_start_time ||
                current,
              current,
              MAX_LAST_POST_TIME_HOURS
            ),
          });
        } else if (currPropData?.progress_status === 4) {
          // Job paused
          this.setState({
            lastPostStatus: isTimeLimitPassed(
              currPropData.progress_end_time || current,
              current,
              MAX_RUN_TIME_PAUSED_HOURS
            ),
          });
        }
      }
    }

    if (currPropData?.card_color !== prevPropData?.card_color) {
      this.setState({
        color: currPropData.card_color
          ? this.hexToRgb(currPropData.card_color)
          : { r: 255, g: 255, b: 255, a: 1 },
      });
    }
  }

  handlePropData = (inputData) => {
    let data = {};
    if (Array.isArray(inputData)) {
      let isRunning = false;
      let biggestEstimatedCompletionTime = this.utcToLocale(
        inputData[0].estimated_completion_utc_time
      );
      for (let i = 0; i < inputData.length && isRunning === false; i++) {
        let target = inputData[i];
        if (target.isRunning) {
          isRunning = true;
        }

        let estimatedCompletionTime = this.utcToLocale(
          target.estimated_completion_utc_time
        );
        if (biggestEstimatedCompletionTime < estimatedCompletionTime) {
          biggestEstimatedCompletionTime = estimatedCompletionTime;
        }
      }

      let hspop_no = null;
      if (inputData.length === 1) {
        hspop_no = inputData[0].hspop_no;
      } else {
        hspop_no = inputData
          .map((obj) => obj.hspop_no)
          .filter((targetHspop) => !!targetHspop)
          .join(', ');
      }

      data = {
        ...inputData[0],
        isRunning: isRunning,
        hspop_no,
        part_count: inputData[inputData.length - 1].part_count,
        estimated_completion_utc_time: biggestEstimatedCompletionTime,
      };
    } else {
      data = inputData;
    }
    return data;
  };

  // --- Color Picker ---
  handleClickOnColorPicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleCloseOnColorPicker = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeOnColorPicker = (color, event) => {
    // console.log('TEST:', event);
    if (JSON.stringify(this.state.color) !== JSON.stringify(color.rgb)) {
      this.setState({ color: color.rgb }, () => {
        if (Array.isArray(this.props.data)) {
          for (const data of this.props.data) {
            this.updateColorCall(data.id, color.hex.toUpperCase());
          }
        } else {
          this.updateColorCall(this.props.data.id, color.hex.toUpperCase());
        }
      });
    }
    if (event.type === 'click') {
      this.handleCloseOnColorPicker();
    }
  };

  hexToRgb(hex) {
    const hex_r = hex.substring(1, 3);
    const hex_g = hex.substring(3, 5);
    const hex_b = hex.substring(5, 7);

    return {
      r: parseInt(hex_r, 16),
      g: parseInt(hex_g, 16),
      b: parseInt(hex_b, 16),
      a: 1,
    };
  }

  updateColorCall(id, hex) {
    axios.put(CONST_API_URL + '/item/color', {
      id: id,
      hex: hex,
    });
  }
  // --------------------

  utcToLocale(utcDate) {
    return moment.utc(utcDate).local().format('YYYY-MM-DD HH:mm');
  }

  findWorkcenterName = (workcenter_no) => {
    const defaultName = 'N/A';
    if (!workcenter_no) {
      return defaultName;
    } else {
      const workcenters = this.props.workcenterList;
      return (
        workcenters?.find((wc) => wc.workcenter === workcenter_no)
          ?.workcenter_name || defaultName
      );
    }
  };

  render() {
    const cardData = this.handlePropData(this.props.data);
    const workcenterName = this.findWorkcenterName(
      cardData.operation_workcenter
    );
    return (
      <div className="job-card__wrapper" style={{ position: 'relative' }}>
        {this.props.originColumnName !== 'search-result' &&
          (Array.isArray(this.props.data) ? (
            this.props.data.map((data) => (
              <div id={`card-${data.id}`} key={`card-${data.id}`} />
            ))
          ) : (
            <div id={`card-${cardData.id}`} />
          ))}

        <div
          className="job-card"
          onClick={this.props.onClick}
          draggable={this.props.originColumnName !== 'search-result'}
          onDragStart={(ev) => {
            editing = true;
            insertlocation = this.props.id;
            id = {
              OriginIndex: this.props.id,
              OriginColumn: this.props.originColumn,
              OriginColumnName: this.props.originColumnName,
            };
          }}
          onDragEnter={(e) => {
            insertlocation = this.props.id;
          }}
          onDragEnd={() => {
            editing = false;
          }}
          style={{
            zIndex: '1',
            backgroundColor: `rgba(${this.state.color.r},${this.state.color.g},${this.state.color.b},${this.state.color.a})`,
            userSelect: 'none',
          }}
        >
          <div className="job-card__contents">
            <Row>
              <Col span={this.props.lock ? 20 : 24}>
                <h4
                  className={`job-card__heading ${
                    this.state.lastPostStatus ? 'job-card__heading--orange' : ''
                  } ${
                    this.state.setupStatus ? 'job-card__heading--yellow' : ''
                  }`}
                >
                  {cardData.order_no ? cardData.order_no : 'N/A'}(
                  {cardData.item_no ? cardData.item_no : 'N/A'})
                </h4>
              </Col>
              {this.props.lock && (
                <Col span={4} style={{ textAlign: 'right' }}>
                  <LockIcon style={{ color: 'var(--danger)' }} />
                </Col>
              )}
            </Row>
            <p className={`job-card__description`}>
              {cardData.description ? cardData.description : 'N/A'}
            </p>

            <Row>
              <p>
                <img src={CustomerIcon} alt="" className="job-card__icon" />
                <span style={{ fontWeight: '500' }}>Customer:</span>{' '}
                {cardData.customer_name ? cardData.customer_name : 'N/A'}
              </p>
            </Row>

            <Row>
              <Row style={{ marginRight: '2rem' }}>
                <p>
                  <img src={QtyIcon} alt="" className="job-card__icon" />
                  <span style={{ fontWeight: '500' }}>QTY:</span>{' '}
                  {cardData.quantity ? cardData.quantity : 'N/A'}
                </p>
              </Row>
              <Row>
                <p>
                  <img src={DueDateIcon} alt="" className="job-card__icon" />
                  <span style={{ fontWeight: '500' }}>DUE DATE:</span>{' '}
                  {cardData.due_date
                    ? moment(cardData.due_date).format('M/D/yyyy')
                    : 'N/A'}
                </p>
              </Row>
            </Row>

            <Row>
              <p>
                <img src={OperationNoIcon} alt="" className="job-card__icon" />
                <span style={{ fontWeight: '500' }}>OP#:</span>{' '}
                {cardData.operation_no ? (
                  cardData.hspop_no ? (
                    cardData.hspop_no
                  ) : (
                    'TBD'
                  )
                ) : (
                  <span className="text-danger">Not Found</span>
                )}
              </p>
            </Row>

            <Row>
              <p>
                <img src={MaterialIcon} alt="" className="job-card__icon" />
                <span style={{ fontWeight: '500' }}>MATERIAL:</span>{' '}
                {`${cardData.component_no_str}`}
              </p>
            </Row>

            {this.props.originColumn === 'unscheduled' ? (
              <Row>
                <p>
                  <img src={WorkCentreIcon} alt="" className="job-card__icon" />
                  <span style={{ fontWeight: '500' }}>WORK CENTER:</span>{' '}
                  {/* {cardData.operation_workcenter || 'N/A'} */}
                  {workcenterName}
                </p>
              </Row>
            ) : (
              <>
                <Row>
                  <p>
                    <img src={ProgressIcon} alt="" className="job-card__icon" />
                    <span style={{ fontWeight: '500' }}>PROGRESS:</span>{' '}
                    {`${cardData.part_count}/${cardData.quantity}`}
                  </p>
                </Row>

                <Row>
                  {cardData.isRunning ? (
                    <p style={{ marginBottom: 0 }}>
                      <img
                        src={EstimatedTimeIcon}
                        alt=""
                        className="job-card__icon"
                      />
                      <span style={{ fontWeight: '500' }}>ESTIMATED:</span>{' '}
                      {this.utcToLocale(cardData.estimated_completion_utc_time)}
                    </p>
                  ) : (
                    <p style={{ marginBottom: 0 }}>
                      <img
                        src={EstimatedTimeIcon}
                        alt=""
                        className="job-card__icon"
                      />
                      <span style={{ fontWeight: '500' }}>ESTIMATED HOUR:</span>{' '}
                      {(
                        cardData.setup_time +
                        cardData.run_time *
                          (cardData.quantity - cardData.part_count)
                      ).toFixed(2)}
                    </p>
                  )}
                </Row>
              </>
            )}
          </div>
        </div>
        {this.props.originColumn !== 'unscheduled' && (
          <div
            className="signal"
            style={{
              position: 'absolute',
              right: '0.5rem',
              top: '0.5rem',
              zIndex: '2',
            }}
          >
            {/* {cardData.isRunning ? (
              <Brightness1Icon style={{ color: 'var(--green)' }} />
            ) : (
              <Brightness1Icon style={{ color: 'var(--yellow)' }} />
            )} */}
            {!!!cardData.progress_status ? (
              <Brightness1Icon style={{ color: 'var(--gray)' }} />
            ) : cardData.progress_status === 2 ||
              cardData.progress_status === 4 ? (
              <Brightness1Icon style={{ color: 'var(--yellow)' }} />
            ) : (
              <Brightness1Icon style={{ color: 'var(--green)' }} />
            )}
          </div>
        )}
        <div
          className="color-picker"
          style={{
            position: 'absolute',
            right: '0.5rem',
            bottom: '0.7rem',
            zIndex: '2',
          }}
        >
          <img
            src={PaintBrushIcon}
            alt="paint brush"
            width={'20px'}
            onClick={this.handleClickOnColorPicker}
            style={{
              borderRadius: '4px',
              backgroundColor: '#EE2023',
              display: 'inline-block',
              cursor: 'pointer',
            }}
          />
          {/* <div
            style={{
              width: '1.3rem',
              height: '1.3rem',
              borderRadius: '2px',
              padding: '0px',
              background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
              //borderRadius: '1px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={this.handleClickOnColorPicker}
          /> */}
        </div>
        {this.state.displayColorPicker && (
          <div
            style={{
              position: 'absolute',
              zIndex: '3',
              right: '0',
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              }}
              onClick={this.handleCloseOnColorPicker}
            />
            <TwitterPicker
              width="100%"
              color={this.state.color}
              colors={[
                '#FFEAA2',
                '#FF6900',
                '#7BDCB5',
                // '#00D084',
                '#8ED1FC',
                // '#0693E3',
                '#ABB8C3',
                '#EB144C',
                '#F78DA7',
                '#FFFFFF',
              ]}
              triangle={'top-right'}
              onChange={this.handleChangeOnColorPicker}
              // onSwatchHover={(color, event) =>
              //   console.log('TEST:', color, event)
              // }
              styles={{
                default: {
                  card: {},
                },
                swatch: {
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

// class SpecialCard extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       visible: false,
//       tags: [],
//     };
//     this.tags = [];
//   }

//   handlePropData = (inputData) => {
//     let data = {};
//     if (Array.isArray(inputData)) {
//       data = inputData[0];
//     } else {
//       data = inputData;
//     }
//     return data;
//   };

//   render() {
//     const cardData = this.handlePropData(this.props.data);
//     return (
//       <>
//         <div
//           className="job-card"
//           style={{ backgroundColor: 'gold' }}
//           draggable={true}
//           onDragStart={(ev) => {
//             editing = true;
//             insertlocation = this.props.id;
//             id = {
//               OriginIndex: this.props.id,
//               OriginColumn: this.props.originColumn,
//               OriginColumnName: this.props.originColumnName,
//             };
//           }}
//           onDragEnter={(e) => {
//             insertlocation = this.props.id;
//           }}
//           onDragEnd={() => {
//             editing = false;
//           }}
//           onClick={() => console.log('clicked ', this)}
//         >
//           <p
//             className={'item'}
//             style={{ fontSize: '1.75em', textAlign: 'center' }}
//           >
//             {cardData.description ? cardData.description : 'N/A'}
//           </p>
//         </div>
//       </>
//     );
//   }
// }

class Column extends React.Component {
  constructor(props) {
    super(props);
    this.virtuoso = React.createRef(null);
    this.toast = React.createRef(null);
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.scrollToComponent, this.props.scrollToComponent) &&
      this.props.scrollToComponent?.workcenter === this.props.workcenter
    ) {
      this.handleScrollToIndex(this.props.scrollToComponent);
    }
  }

  handleScrollToIndex = (scrollToComponent) => {
    this.virtuoso.current.scrollToIndex({
      index: scrollToComponent.index,
    });
    const target = scrollToComponent;

    setTimeout(() => {
      const element = document.getElementById(`card-${target.itemId}`);

      if (element) {
        element.scrollIntoView({ behavior: 'auto' });
        const jobCardElement =
          element.parentElement?.querySelector('.job-card');
        if (jobCardElement) {
          jobCardElement.classList.add('card-animation-selected');
          setTimeout(
            () => jobCardElement.classList.remove('card-animation-selected'),
            1500
          );
        }
      } else {
        let detailMessageHead = 'Unexpected error occurred';
        let detailMessageTail =
          'Cannot highlight the selected card, please refresh your browser and try again';

        const detailMessage = (
          <>
            {detailMessageHead}
            <br />
            <span style={{ fontWeight: '500', color: 'var(--danger)' }}>
              {detailMessageTail}
            </span>
          </>
        );

        this.toast.show({
          severity: 'warn',
          detail: detailMessage,
          life: 5000,
        });
      }
    }, 50);

    //reset redux data
    store.dispatch({
      type: SCROLL_TO_COMPONENT,
      scrollToComponent: null,
    });
  };

  handleDrop(e, i) {
    console.log('handleDrop');
    if (id.OriginColumn !== 'nothing') {
      e.preventDefault();
      e.stopPropagation();
      this.props.parentCallback(
        Object.assign(
          {
            TargetIndex: insertlocation,
            TargetColumn: this.props.varname,
            TargetColumnName: this.props.workcenter,
            method: 'drop',
          },
          id
        )
      );
    } else {
      console.log('Cant move to Nothing');
    }
    id = { OriginIndex: -2, OriginColumn: 'nothing' };
  }

  // handleGrandParentCallBack = (columnData) => {
  //   this.props.GrandChildCallback(columnData);
  // };

  JobTagColors(name) {
    if (name === 'programming') {
      return '#bdd3d7';
    } else if (name === 'queue') {
      return '#8d9498';
    }
  }

  utcToLocale(utcDate) {
    return moment.utc(utcDate).local().format('YYYY-MM-DD HH:mm');
  }

  renderPlaceholder = () => (
    <div
      className="placeholderJob"
      onDragEnter={() => {
        insertlocation = this.props.data.length;
      }}
      style={{
        border: '4px solid transparent',
        boxShadow: '0,0,0 rgb(0,0,0 /2%)',
        marginBottom: '4vh',
        zIndex: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--primary)',
        fontSize: '1.2rem',
      }}
    >
      {/* {this.props.displayItemLimit !== 0 &&
    this.props.data?.length - this.props.displayItemLimit > 0 && (
      <p>
        ...{this.props.data?.length - this.props.displayItemLimit}{' '}
        more items
      </p>
    )} */}
    </div>
  );

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <div className={'ColumnContainer'}>
          <div className="ColumnHeader">
            <h3
              style={{
                textAlign: 'center',
                margin: 0,
                color: this.props.danger ? 'var(--danger)' : '',
              }}
            >
              {this.props.name}
            </h3>
          </div>
          {/**SEPARATOR */}
          {/*----------------------------------------------*/}

          <div className="Column">
            {this.props.spinning ? (
              <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Spin spinning={this.props.spinning} />
              </Row>
            ) : (
              <Virtuoso
                ref={this.virtuoso}
                className="virtuoso-list"
                components={{
                  Item: HeightPreservingItem,
                  Footer: this.renderPlaceholder,
                }}
                data={this.props.data}
                itemContent={(index, x) => {
                  return (
                    <Card
                      // GrandparentCallback={this.handleGrandParentCallBack}
                      key={this.props.name + index}
                      id={index}
                      data={x}
                      originColumn={this.props.varname}
                      originColumnName={this.props.workcenter}
                      workcenterList={this.props.workcenterList}
                      onClick={() => this.props.displayMoDetailModal(x)}
                    />
                  );
                }}
                id={'Column#' + this.props.varname}
                onDrop={(e) => this.handleDrop(e)}
                onDragOver={(e) => handleDragOver(e)}
                onScroll={this.handleScroll}
              />
            )}
          </div>
        </div>
        {/*---------------------MO Detail Dialog-------------------------*/}
        {/* <Dialog
          visible={this.state.visible}
          style={{
            width: 'min-content',
            // height: '60vh',
            backgroundColor: 'white',
            zIndex: 60,
          }}
          modal={true}
          onHide={(e) => this.setState({ visible: false })}
          draggable={false}
          dismissableMask={true}
          contentStyle={{ height: '100%', padding: '0px' }}
          header={this.renderDialogHeader}
          footer={this.renderDialogFooter}
        >
          <DataTable
            value={
              Array.isArray(this.state.dialogData)
                ? this.state.dialogData
                : [this.state.dialogData]
            }
          >
            <PrimeColumn
              header="Status"
              body={(rowData) => {
                return !!!rowData.progress_status ? (
                  <>
                    <Brightness1Icon style={{ color: 'var(--gray)' }} />{' '}
                    <span>Awaiting</span>
                  </>
                ) : rowData.progress_status === 2 ||
                  rowData.progress_status === 4 ? (
                  <>
                    <Brightness1Icon style={{ color: 'var(--yellow)' }} />{' '}
                    <span>Paused</span>
                  </>
                ) : (
                  <>
                    <Brightness1Icon style={{ color: 'var(--green)' }} />{' '}
                    <span>Running</span>
                  </>
                );
              }}
              style={{ width: '8rem' }}
            />
            <PrimeColumn
              // field="operation_no"
              field="hspop_no"
              header="Op. No"
              style={{ width: '6rem' }}
            />
            <PrimeColumn
              header="Progress"
              body={(rowData) => `${rowData.part_count}/${rowData.quantity}`}
              style={{ width: '8rem' }}
            />
            <PrimeColumn
              header="Estimated time/hour"
              body={(rowData) =>
                rowData.isRunning
                  ? `${this.utcToLocale(rowData.estimated_completion_utc_time)}`
                  : `${(
                      rowData.setup_time +
                      rowData.run_time * (rowData.quantity - rowData.part_count)
                    ).toFixed(2)} hour`
              }
              style={{ width: '10rem' }}
            />
            <PrimeColumn
              header="Routing Description"
              body={(rowData) => {
                return (
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {rowData.routing_description}
                  </span>
                );
              }}
              style={{ width: '40rem' }}
            />
          </DataTable>
        </Dialog> */}
        {/*---------------------MO Detail Dialog End-------------------------*/}
        {/*---------------------Create Next Operation Dialog-------------------------*/}
        {/* <OperationDialog
          visible={this.state.dialogVisible2}
          onHide={() => this.setState({ dialogVisible2: false })}
          orderNo={
            Array.isArray(this.state.dialogData)
              ? this.state.dialogData[0]?.order_no
              : this.state.dialogData?.order_no
          }
          tableData={this.state.nextOperationData}
          headingFragment={
            <h4>Do you want to create a card for below operations?</h4>
          }
          onClickYes={() => this.handleCreateNextOperation()}
          onClickNo={() => this.setState({ dialogVisible2: false })}
          disablePostButton={this.state.nextOperationData.length === 0}
          loading={this.state.loadingDialog2}
        /> */}
        {/*---------------------Create Next Operation Dialog End-------------------------*/}
        {/*---------------------Revert to Previous Operation Dialog-------------------------*/}
        {/* <OperationDialog
          visible={this.state.dialogVisible6}
          onHide={() => this.setState({ dialogVisible6: false })}
          orderNo={
            Array.isArray(this.state.dialogData)
              ? this.state.dialogData[0]?.order_no
              : this.state.dialogData?.order_no
          }
          tableData={this.state.prevOperationData}
          headingFragment={
            <>
              <h4>
                Do you want to revert this card to below operations?
                <br />
                <span className="text-danger">
                  ※ This card will be moved to the target work center and timer
                  in Router will be reset
                </span>
              </h4>
            </>
          }
          onClickYes={() => this.handleRevertToPrevOperation()}
          onClickNo={() => this.setState({ dialogVisible6: false })}
          disablePostButton={this.state.prevOperationData.length === 0}
          loading={this.state.loadingDialog6}
        /> */}
        {/*---------------------Revert to Previous Operation Dialog End-------------------------*/}

        {/*---------------------Move to the top Dialog-------------------------*/}
        {/* <ConfirmDialog
          visible={this.props.moveToTopDialogVisible}
          position="top"
          style={{
            backgroundColor: 'white',
            zIndex: 61,
          }}
          modal={true}
          onHide={() => this.props.setMoveToTopDialogVisible(false)}
          draggable={false}
          dismissableMask={true}
          contentStyle={{ height: '100%', padding: '0px' }}
          header={
            <>
              <h2>
                MO:{' '}
                {Array.isArray(this.props.dialogData)
                  ? this.props.dialogData[0]?.order_no
                  : this.props.dialogData?.order_no}
              </h2>
              <h4>Do you want to move this item to the top?</h4>
            </>
          }
          footer={
            <div>
              <Button
                label="No"
                icon="pi pi-times"
                onClick={() => this.props.setMoveToTopDialogVisible(false)}
                className="p-button-text"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                onClick={() => this.handleMoveToTop()}
                autoFocus
              />
            </div>
          }
        /> */}
        {/*---------------------Move to the top Dialog End-------------------------*/}
        {/*---------------------Post History Dialog-------------------------*/}
        {/* <PostHistoryDialog
          visible={this.state.dialogVisible4}
          onHide={() => this.setState({ dialogVisible4: false })}
          dialogData={this.state.dialogData}
        /> */}
        {/*---------------------Post History Dialog End-------------------------*/}
        {/*---------------------Card Movement History Dialog-------------------------*/}
        {/* <CardMovementHistoryDialog
          visible={this.state.dialogVisible5}
          onHide={() => this.setState({ dialogVisible5: false })}
          dialogData={this.state.dialogData}
        /> */}
        {/*---------------------Card Movement History Dialog End-------------------------*/}
      </>
    );
  }
}

class Unscheduled extends React.Component {
  // Column Of Unscheduled

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dialogData: [],
      selected: 0,
      programs: [],
      program1: [],
      program2: [],
      chosenprogram: -1,
      visible: false,
      search: {
        inputValue: '',
        selectedSearchables: [],
      },
    };
    this.virtuoso = React.createRef(null);
    this.toast = React.createRef(null);
    // console.log(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.scrollToComponent, this.props.scrollToComponent) &&
      this.props.scrollToComponent?.workcenter === 'Unscheduled'
    ) {
      this.handleScrollToIndex(this.props.scrollToComponent);
    }
  }

  handleScrollToIndex = (scrollToComponent) => {
    console.log('handleScrollToIndex - unscheduled', scrollToComponent);
    this.virtuoso.current.scrollToIndex({
      index: scrollToComponent.index,
    });
    const target = scrollToComponent;

    setTimeout(() => {
      const element = document.getElementById(`card-${target.itemId}`);

      if (element) {
        element.scrollIntoView({ behavior: 'auto' });
        const jobCardElement =
          element.parentElement?.querySelector('.job-card');
        if (jobCardElement) {
          jobCardElement.classList.add('card-animation-selected');
          setTimeout(
            () => jobCardElement.classList.remove('card-animation-selected'),
            1500
          );
        }
      } else {
        let detailMessageHead = 'Unexpected error occurred';
        let detailMessageTail =
          'Cannot highlight the selected card, please refresh your browser and try again';

        const detailMessage = (
          <>
            {detailMessageHead}
            <br />
            <span style={{ fontWeight: '500', color: 'var(--danger)' }}>
              {detailMessageTail}
            </span>
          </>
        );

        this.toast.show({
          severity: 'warn',
          detail: detailMessage,
          life: 5000,
        });
      }
    }, 50);

    //reset redux data
    store.dispatch({
      type: SCROLL_TO_COMPONENT,
      scrollToComponent: null,
    });
  };

  handleScroll = (e) => {
    // useless function here incase if we need it.
    if (
      Math.floor(e.target.scrollHeight - e.target.scrollTop) <=
      Math.floor(e.target.clientHeight)
    ) {
      //this.props.callback()
    }
  };

  handleDate(data) {
    // console.log('Calendar:', this.calendar);
    // console.log(data);
    this.setState({ date: data });
    if (data[1] === null) {
      // NOTHING ELSE
    } else {
      this.props.passUnscheduledDate(data); // PASS DATA
    }
  }

  displayModal = (data) => {
    // displays alternate popup (split programs/job)
    this.setState({ visible: true, dialogData: data }, () =>
      console.log(this.state.dialogData)
    );
  };

  // handleGrandParentCallBack = (columnData) => {
  //   //Job Drag Callback
  //   this.props.GrandChildCallback(columnData);
  // };

  handleDrop(e, i) {
    // Handle Drop, when object from right columns are dropped on unscheduled
    if (id.OriginColumn !== 'nothing') {
      e.preventDefault();
      e.stopPropagation();
      this.props.parentCallback(
        Object.assign(
          {
            TargetIndex: insertlocation,
            TargetColumn: this.props.varname,
            method: 'unschedule',
          },
          id
        )
      );
    } else {
      console.log('Cant move to Nothing');
    }
    id = { OriginIndex: -2, OriginColumn: 'nothing' };
  }

  renderDialogHeader = () => {
    const dialogData = this.state.dialogData;
    return (
      <div>
        <Row>
          <Col>
            <span>MO: {dialogData.order_no}</span>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <span>Operation No: {dialogData.operation_no || 'N/A'}</span>
          </Col>
          <Col>
            <span>Work Center: {dialogData.operation_workcenter || 'N/A'}</span>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <div
          className="UnscheduledContainer"
          style={{
            // display: 'inline-block',
            display: 'flex',
            flexDirection: 'column',
            // float: 'left',
            border: '4px solid #dcdcdc',
            backgroundColor: '#dcdcdc',
            borderTopLeftRadius: '.6rem',
            borderRight: '0px solid transparent',
            width: '100%',
            position: 'relative',
            ...this.props.style,
          }}
        >
          <div style={{ height: '3rem' }}>
            <h1
              style={{
                textAlign: 'center',
                margin: 0,
                fontSize: '2rem',
                fontWeight: '600',
              }}
            >
              {this.props.name}
            </h1>
            <Divider
              style={{ marginTop: '6px', marginBottom: '6px', width: '100%' }}
            />
          </div>

          {/* <div
            id="unscheduled"
            className="unscheduled__column"
            onDrop={(e) => this.handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onScroll={this.handleScroll}
          >
            <Spin spinning={this.props.spinning} style={{ top: '2rem' }}>
              {this.props.data.map((x, index) => {
                if (
                  this.props.displayItemLimit === 0 ||
                  (this.props.displayItemLimit > 0 &&
                    index < this.props.displayItemLimit)
                ) {
                  return x.item_no !== 'FILLER' ? (
                    <Card
                      // GrandparentCallback={this.handleGrandParentCallBack}
                      key={this.props.name + index}
                      id={index}
                      data={this.props.data[index]}
                      originColumn={this.props.varname}
                      onClick={() => this.displayModal(this.props.data[index])}
                    />
                  ) : (
                    <SpecialCard
                      // GrandparentCallback={this.handleGrandParentCallBack}
                      key={this.props.name + index}
                      id={index}
                      data={this.props.data[index]}
                      originColumn={this.props.varname}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </Spin>
            {this.props.displayItemLimit !== 0 &&
              this.props.data?.length - this.props.displayItemLimit > 0 && (
                <div
                  className="placeholderJob"
                  // onDragOver={() => (insertlocation = this.props.data.length)}
                  onDragEnter={() => {
                    insertlocation =
                      this.props.displayItemLimit || this.props.data.length;
                  }}
                  style={{
                    border: '4px solid transparent',
                    boxShadow: '0,0,0 rgb(0,0,0 /2%)',
                    marginBottom: '4vh',
                    zIndex: '0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'var(--primary)',
                    fontSize: '1.2rem',
                  }}
                >
                  {
                    <p>
                      ...{this.props.data?.length - this.props.displayItemLimit}{' '}
                      more items
                    </p>
                  }
                </div>
              )}
          </div> */}
          <div
            id="unscheduled"
            className="unscheduled__column"
            onDrop={(e) => this.handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onScroll={this.handleScroll}
          >
            {this.props.spinning ? (
              <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Spin
                  spinning={this.props.spinning}
                  style={{ top: '2rem' }}
                ></Spin>
              </Row>
            ) : (
              <Virtuoso
                ref={this.virtuoso}
                className="virtuoso-list"
                components={{
                  Item: HeightPreservingItem,
                }}
                data={this.props.data}
                itemContent={(index, x) => {
                  return (
                    <Card
                      // GrandparentCallback={this.handleGrandParentCallBack}
                      key={this.props.name + index}
                      id={index}
                      data={x}
                      originColumn={this.props.varname}
                      workcenterList={this.props.workcenterList}
                      onClick={() => this.displayModal(x)}
                    />
                  );
                }}
                id={'Column#' + this.props.varname}
                onDrop={(e) => this.handleDrop(e)}
                onDragOver={(e) => handleDragOver(e)}
                onScroll={this.handleScroll}
              />
            )}
            {/* {this.props.data.map((x, index) => {
                if (
                  this.props.displayItemLimit === 0 ||
                  (this.props.displayItemLimit > 0 &&
                    index < this.props.displayItemLimit)
                ) {
                  return x.item_no !== 'FILLER' ? (
                    <Card
                      // GrandparentCallback={this.handleGrandParentCallBack}
                      key={this.props.name + index}
                      id={index}
                      data={this.props.data[index]}
                      originColumn={this.props.varname}
                      onClick={() => this.displayModal(this.props.data[index])}
                    />
                  ) : (
                    <SpecialCard
                      // GrandparentCallback={this.handleGrandParentCallBack}
                      key={this.props.name + index}
                      id={index}
                      data={this.props.data[index]}
                      originColumn={this.props.varname}
                    />
                  );
                } else {
                  return <></>;
                }
              })} */}
            {/* </Spin> */}
          </div>
        </div>

        <Dialog
          visible={this.state.visible}
          style={{
            // width: 'min-content',
            // height: '60vh',
            backgroundColor: 'white',
            zIndex: 60,
          }}
          modal={true}
          onHide={(e) => this.setState({ visible: false })}
          draggable={false}
          dismissableMask={true}
          contentStyle={{
            height: '100%',
            padding: '0px',
            marginBottom: '2rem',
          }}
          header={this.renderDialogHeader}
          // footer={this.renderDialogFooter}
        >
          <DataTable
            value={
              Array.isArray(this.state.dialogData)
                ? this.state.dialogData
                : [this.state.dialogData]
            }
          >
            <PrimeColumn
              header="Status"
              body={(rowData) => {
                return rowData.isRunning === true ? (
                  <>
                    <Brightness1Icon style={{ color: 'var(--green)' }} />{' '}
                    <span>Running</span>
                  </>
                ) : (
                  <>
                    <Brightness1Icon style={{ color: 'var(--yellow)' }} />{' '}
                    <span>Awaiting</span>
                  </>
                );
              }}
              style={{ width: '8rem' }}
            />
            <PrimeColumn
              // field="operation_no"
              field="hspop_no"
              header="Op. No"
              style={{ width: '6rem' }}
            />
            <PrimeColumn
              header="Progress"
              body={(rowData) => `${rowData.part_count}/${rowData.quantity}`}
              style={{ width: '8rem' }}
            />
            <PrimeColumn
              header="Estimated time/hour"
              body={(rowData) =>
                rowData.isRunning
                  ? `${this.utcToLocale(rowData.estimated_completion_utc_time)}`
                  : `${(
                      rowData.setup_time +
                      rowData.run_time * (rowData.quantity - rowData.part_count)
                    ).toFixed(2)} hour`
              }
              style={{ width: '10rem' }}
            />
            <PrimeColumn
              header="Routing Description"
              body={(rowData) => {
                return (
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {rowData.routing_description}
                  </span>
                );
              }}
              style={{ width: '40rem' }}
            />
          </DataTable>
        </Dialog>
      </>
    );
  }
}

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [[], [], [], [], [], [], []],
      // date: this.props.date,
      unscheduled: [],
      standard: [],
      search: {
        inputValue: '',
        selectedSearchables: [],
      },
      searchResultData: [],
      searchResultMessage: '',
      desc: '',
      editing: false,
      keyword: '',
      dateFilter: null,
      // dataLoading: true,
      unscheduledLoading: true,
      scheduledLoading: true,
      searchLoading: false,

      //--- dialogs ---
      dialogData: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false, // To view post history
      dialogVisible5: false, // To view movement history
      dialogVisible6: false, // Revert to previous operation dialog
      loadingDialog2: true,
      loadingDialog6: true,
      nextOperationData: [],
      prevOperationData: [],
      loadingCreateNextOp: false,
      loadingRevertToPrevOp: false,
      //--- dialogs end ---
    };
    this.toast = React.createRef(null);
    this.UPDATE_INTERVAL = null;
  }

  download(csv, filename) {
    const a = document.createElement('a');
    a.href = encodeURI(csv);
    a.download = filename + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  componentDidUpdate(prevProps) {
    // console.log('TEST:', prevProps, this.props);

    if (this.props.workcenters.length !== prevProps.workcenters.length) {
      this.UpdateScheduled();
    }
    if (
      this.props.dataRefreshIntervalMin !== prevProps.dataRefreshIntervalMin
    ) {
      this.setUpdateInterval(
        Number(this.props.dataRefreshIntervalMin) * 60 * 1000
      );
    }

    if (!_.isEqual(prevProps.updateItemFlag, this.props.updateItemFlag)) {
      if (this.props.updateItemFlag.scheduled === true) {
        this.UpdateScheduled();
        store.dispatch({
          type: UPDATE_SCHEDULED_ITEM,
          updateScheduledItem: false,
        });
      }
      if (this.props.updateItemFlag.unscheduled === true) {
        this.updateUnscheduled();
        if (this.state.keyword.length > 0) {
          this.searchFunction();
        }
        store.dispatch({
          type: UPDATE_UNSCHEDULED_ITEM,
          updateUnscheduledItem: false,
        });
      }
    }
  }

  UpdateScheduled = () => {
    // if (!this.props.date) {
    //   return;
    // }
    this.setState({ scheduledLoading: true });
    axios
      .get(CONST_API_URL + '/item', {
        params: {
          status: 'Scheduled',
          location: this.props.division?.code,
        },
      })
      .then((res) => {
        const data = res.data;
        this.setState({ data: data });
      })
      .finally(() => this.setState({ scheduledLoading: false }));
  };
  updateStandard() {
    axios.get(CONST_API_URL + '/item/sb/template').then((res) => {
      const data = res.data;
      this.setState({ standard: data });
    });
  }

  updateUnscheduled() {
    this.setState({ unscheduledLoading: true });

    axios
      .get(CONST_API_URL + '/item', {
        params: {
          status: 'Unscheduled',
          location: this.props.division?.code,
        },
      })
      .then((res) => {
        const data = res.data;
        this.setState({ unscheduled: data });
      })
      .finally(() => this.setState({ unscheduledLoading: false }));
  }
  dumbLazyUnscheduledCall() {
    if (this.state.keyword.length > 0 || this.state.dateFilter !== null) {
      this.searchFunction();
    }
    this.updateUnscheduled();
  }
  MasterUpdate() {
    console.log('UPDATING!');
    if (editing !== true) {
      this.UpdateScheduled();
      this.dumbLazyUnscheduledCall();
      this.updateStandard();
    } else {
      console.log('CANT DO THAT USER IS EDITING');
    }
  }

  componentDidMount() {
    this.MasterUpdate();
    this.setUpdateInterval(
      Number(this.props.dataRefreshIntervalMin) * 60 * 1000
    );
  }

  componentWillUnmount() {
    if (this.UPDATE_INTERVAL) {
      clearInterval(this.UPDATE_INTERVAL);
    }
  }

  setUpdateInterval(ms) {
    // console.log('TEST-setUpdateInterval', ms);
    if (Number.isNaN(ms)) {
      throw new Error('Failed to set UpdateInterval, input value:', ms);
    }
    this.UPDATE_INTERVAL = setInterval(() => {
      this.MasterUpdate();
      this.props.updateLatestSync();
    }, ms);
  }

  confirm = (data) => {
    console.log('confirm:', data);
    if (
      data.OriginColumn === data.TargetColumn &&
      data.OriginIndex === data.TargetIndex
    ) {
      return;
    } else if (
      data.OriginColumn !== 'standard' &&
      data.TargetColumn !== 'standard' &&
      data.TargetColumn !== 'unscheduled'
    ) {
      const masterOriginIdx = this.state.data.findIndex(
        (obj) => obj.workcenter === data.OriginColumnName
      );

      const dataObj =
        data.OriginColumn === 'unscheduled'
          ? this.state.unscheduled[data.OriginIndex]
          : this.state.data[masterOriginIdx].data[data.OriginIndex][0];
      if (
        dataObj.operation_workcenter !== data.TargetColumnName &&
        dataObj.item_no !== 'FILLER'
      ) {
        // const designatedWcObj = this.props.workcenterList?.find(
        //   (wc) => wc.workcenter === dataObj.operation_workcenter
        // );
        // const targetWcObj = this.props.workcenterList?.find(
        //   (wc) => wc.workcenter === data.TargetColumnName
        // );

        // const DsgWcDisplayName = designatedWcObj
        //   ? `${designatedWcObj.workcenter_name}${
        //       designatedWcObj.workcenter !== designatedWcObj.workcenter_name
        //         ? `(${designatedWcObj.workcenter})`
        //         : ''
        //     }`
        //   : 'N/A';
        // const TgtWcDisplayName = targetWcObj
        //   ? `${targetWcObj.workcenter_name}${
        //       targetWcObj.workcenter !== targetWcObj.workcenter_name
        //         ? `(${targetWcObj.workcenter})`
        //         : ''
        //     }`
        //   : 'N/A';

        const designatedWcName = this.props.workcenterList?.find(
          (wc) => wc.workcenter === dataObj.operation_workcenter
        )?.workcenter_name;
        const targetWcName = this.props.workcenterList?.find(
          (wc) => wc.workcenter === data.TargetColumnName
        )?.workcenter_name;
        confirmDialog({
          message: (
            <p>
              Designated Work Center (NAV):{' '}
              <b>{designatedWcName || dataObj.operation_workcenter || 'N/A'}</b>
              {/* <b>{DsgWcDisplayName}</b> */}
              <br />
              Targetted Work Center:{' '}
              <b>{targetWcName || data.TargetColumnName}</b>
              {/* <b>{TgtWcDisplayName}</b> */}
              <br />
              <br />
              Are you sure you want to proceed?
            </p>
          ),
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => this.handleColumnCallback(data),
          // reject: this.reject,
        });
        return;
      } else {
        this.handleColumnCallback(data);
      }
    } else {
      this.handleColumnCallback(data);
    }
  };

  handleColumnCallback = async (data) => {
    console.log('handleColumnCallback:', data);

    let MasterCopy = _.cloneDeep(this.state.data);
    let Unscheduled = [];
    let Standard = [];
    // console.log('MasterCopy', MasterCopy);

    let masterOriginIdx = MasterCopy.findIndex(
      (obj) => obj.workcenter === data.OriginColumnName
    );
    let masterTargetIdx = MasterCopy.findIndex(
      (obj) => obj.workcenter === data.TargetColumnName
    );

    if (
      data.OriginColumn === 'unscheduled' ||
      data.TargetColumn === 'unscheduled'
    ) {
      Unscheduled = _.cloneDeep(this.state.unscheduled);
    }
    if (data.OriginColumn === 'standard' || data.TargetColumn === 'standard') {
      Standard = _.cloneDeep(this.state.standard);
    }

    let newColumn =
      data.TargetColumn !== 'unscheduled'
        ? data.TargetColumn !== 'standard'
          ? masterTargetIdx !== -1
            ? _.cloneDeep(MasterCopy[masterTargetIdx].data)
            : []
          : Standard
        : Unscheduled;

    let oldColumn =
      data.OriginColumn !== 'unscheduled'
        ? data.OriginColumn !== 'standard'
          ? masterOriginIdx !== -1
            ? _.cloneDeep(MasterCopy[masterOriginIdx].data)
            : []
          : Standard
        : Unscheduled;

    let spliceBackup = _.cloneDeep(oldColumn);
    let single = false;

    if (data.TargetColumn !== data.OriginColumn) {
      // columns are different!
      oldColumn.splice(data.OriginIndex, 1);
      newColumn.splice(insertlocation, 0, spliceBackup[data.OriginIndex]);
      if (
        data.OriginColumn !== 'standard' &&
        data.OriginColumn !== 'unscheduled'
      ) {
        MasterCopy[masterOriginIdx].data = oldColumn;
      }
    } else {
      // Columns are the same!
      single = true;
      newColumn.splice(data.OriginIndex, 1);
      newColumn.splice(insertlocation, 0, spliceBackup[data.OriginIndex]);
    }

    let calls = [];
    if (single === true) {
      if (data.OriginColumn === 'unscheduled') {
        this.dumbLazyUnscheduledCall();
        return 'Done';
      } else if (data.OriginColumn !== 'standard') {
        MasterCopy[masterOriginIdx].data = newColumn;
        calls.push({
          data: this.processColumn(newColumn),
          // index: data.OriginColumn,
          workcenter: data.TargetColumnName,
          actionData: data,
        });
      }
    } else {
      if (data.TargetColumn === 'unscheduled') {
        if (
          this.state.data[masterOriginIdx].data[data.OriginIndex].length > 1
        ) {
          this.toast.show({
            severity: 'error',
            summary: 'Error : Action Rejected',
            detail: 'Multiple operation item cannot be unscheduled',
            life: 3000,
          });
          return 'ERROR~ CANT PUT ITEM THERE';
        }
      } else if (
        data.OriginColumn === 'unscheduled' &&
        !!!this.state.unscheduled[data.OriginIndex]?.operation_no
      ) {
        this.toast.show({
          severity: 'error',
          summary: 'Error : Action Rejected',
          detail: 'No routings found, please refresh the production order',
          life: 3000,
        });
        return 'ERROR~ CANT PUT ITEM THERE';
      } else if (data.TargetColumn === 'standard') {
        if (spliceBackup[data.OriginIndex][0].item_no === 'FILLER') {
          //TODO CALL DELETE
        } else {
          this.toast.show({
            severity: 'error',
            summary: 'Error : Action Rejected',
            detail:
              'Standard block items are only allowed to move into this list',
            life: 3000,
          });
          return 'ERROR~ CANT PUT ITEM THERE';
        }
      } else {
        calls.push({
          data: this.processColumn(newColumn),
          // index: data.TargetColumn,
          workcenter: data.TargetColumnName,
          actionData: data,
        });
        if (masterTargetIdx !== -1) {
          MasterCopy[masterTargetIdx].data = newColumn;
        } else {
          MasterCopy.push({
            workcenter: data.TargetColumnName,
            data: newColumn,
          });
        }
      }

      if (data.OriginColumn === 'unscheduled') {
        // ALSO CALL SINGLE API
        this.setState({ unscheduled: oldColumn });
      } else if (data.OriginColumn !== 'standard') {
        calls.push({
          data: this.processColumn(oldColumn),
          // index: data.OriginColumn,
          workcenter: data.OriginColumnName,
          actionData: data,
        });
        if (masterOriginIdx !== -1) {
          MasterCopy[masterOriginIdx].data = oldColumn;
        } else {
          MasterCopy.push({
            workcenter: data.OriginColumnName,
            data: oldColumn,
          });
        }
      }
    }

    await this.updateCall(calls);

    if (data.TargetColumn === 'unscheduled') {
      // ALSO CALL SINGLE API
      if (spliceBackup[data.OriginIndex].item_no === 'FILLER') {
        //TODO CALL DELETE
      } else {
        this.dumbLazyUnscheduledCall();
      }
    }

    return 'done';
  };

  // processColumn(Column) {
  //   let ids = [];
  //   for (let i = 0; i < Column.length; i++) {
  //     ids.push(Column[i].id);
  //   }
  //   return ids;
  // }

  processColumn(Column) {
    let ids = [];
    for (let i = 0; i < Column.length; i++) {
      if (Array.isArray(Column[i])) {
        const innerArr = [];
        for (let j = 0; j < Column[i].length; j++) {
          innerArr.push(Column[i][j].id);
        }
        ids.push(innerArr);
      } else {
        ids.push(Column[i].id);
      }
    }
    return ids;
  }

  async updateCall(columns) {
    const user = store.getState().user;
    await axios
      .put(CONST_API_URL + '/item', {
        columns,
        user,
      })
      .then((res) => {
        const data = res.data;
        let copy = _.cloneDeep(this.state.data);
        for (let i = 0; i < columns.length; i++) {
          const idx = copy.findIndex(
            (obj) => obj.workcenter === columns[i].workcenter
          );

          // if (idx !== -1) {
          //   copy[idx].data = data[i];
          // } else {
          //   copy.push({
          //     workcenter: columns[i].workcenter,
          //     data: data[i],
          //   });
          // }
          if (idx !== -1) {
            copy[idx] = data[i];
          } else {
            copy.push(data[i]);
          }
        }
        this.setState({ data: copy });
      });
  }

  makeStandard() {
    console.log('hello!');
    let desc = this.state.desc;
    if (this.state.calling === true) {
      this.toast.show({
        severity: 'error',
        summary: "Error : Don't spam the button!",
        life: 3000,
      });
      return;
    }
    if (desc.length === 0) {
      this.toast.show({
        severity: 'error',
        summary: 'Error : Please Enter a Description',
        life: 3000,
      });
      return; // TODO ADD TOAST
    } else {
      //call API
      this.setState({ calling: true });
      axios
        .post(CONST_API_URL + '/item/sb/template', {
          description: desc,
        })
        .then((res) => {
          this.setState({ calling: false });
          this.setState({ visible: false });
          this.setState({ desc: '' });
          this.updateStandard();
        });
    }
  }

  search = (data) => {
    if (!!data?.inputValue) {
      this.setState({ keyword: data.inputValue }, () => this.searchFunction());
    } else {
      this.setState({ keyword: '', searchResultData: [] });
    }
  };

  UnscheduledDate = (data) => {
    this.setState({ dateFilter: data }, () => this.searchFunction());
  };

  searchFunction() {
    this.setState({ searchLoading: true, searchResultMessage: '' });
    axios
      .get(CONST_API_URL + '/item/search', {
        params: {
          keyword: this.state.keyword,
          location: this.props.division?.code,
          // startdate: this.state.dateFilter
          //   ? this.state.dateFilter[0].toISOString().slice(0, 10)
          //   : null,
          // enddate: this.state.dateFilter
          //   ? this.state.dateFilter[1].toISOString().slice(0, 10)
          //   : null,
        },
      })
      .then((res) => {
        const data = res.data;
        // console.log(data);
        if (Array.isArray(data)) {
          this.setState({ searchResultData: data });
        } else if (data.message) {
          this.setState({
            searchResultMessage: data.message,
            searchResultData: [],
          });
        }
      })
      .catch((error) => {
        console.log('Error - searchFunction', error);
        this.setState({
          searchResultMessage:
            'Unexpected error occurred, please try again or check your connection',
        });
      })
      .finally(() => this.setState({ searchLoading: false }));
  }

  getWorkcenterData(workcenter) {
    const idx = this.state.data.findIndex(
      (obj) => obj?.workcenter === workcenter
    );
    const result = idx === -1 ? [] : this.state.data[idx].data;
    return result;
  }

  displayMoDetailModal = (data) => {
    this.setState({ dialogVisible: true, dialogData: data });
  };

  utcToLocale(utcDate) {
    return moment.utc(utcDate).local().format('YYYY-MM-DD HH:mm');
  }

  renderDialogHeader = () => {
    const dialogData = Array.isArray(this.state.dialogData)
      ? this.state.dialogData[0]
      : this.state.dialogData;

    return (
      <div>
        <Row style={{ marginBottom: '0.4rem' }}>
          <Col>
            <h2>MO: {dialogData.order_no}</h2>
          </Col>
        </Row>
        <Row>
          <span>Customer Code: {dialogData.customer_name || 'N/A'}</span>
        </Row>
        <Row>
          {/* <Col span={6}>
            <span>Operation No: {dialogData.operation_no || 'N/A'}</span>
          </Col> */}
          <Col span={9}>
            <span>
              Original Work Center No:{' '}
              {dialogData.operation_workcenter || 'N/A'}
            </span>
          </Col>
          <Col>
            <span>
              Scheduled Work Center No: {dialogData.workcenter || 'N/A'}
            </span>
          </Col>
        </Row>
      </div>
    );
  };

  renderDialogFooter = () => {
    return (
      <div style={{ marginTop: '1.5rem' }}>
        <Button
          label="Revert to previous operation"
          className="p-button-danger"
          onClick={this.handleOnClickRevertToPrevOp}
        />
        <Button
          label="View Movement History"
          onClick={this.handleOnClickViewMovementHistory}
        />
        <Button
          label="View Post History"
          onClick={this.handleOnClickViewPostHistory}
        />
        <Button label="Move to the top" onClick={this.handleOnClickMoveToTop} />
        <Button
          label="Create next operation card"
          onClick={this.handleOnClickCreateNextOp}
        />
      </div>
    );
  };

  handleOnClickViewMovementHistory = () => {
    this.setState({ dialogVisible5: true });
  };

  handleOnClickViewPostHistory = () => {
    this.setState({ dialogVisible4: true });
  };

  handleOnClickMoveToTop = () => {
    this.setState({ dialogVisible3: true });
  };

  handleOnClickCreateNextOp = () => {
    const target = Array.isArray(this.state.dialogData)
      ? this.state.dialogData[0]
      : this.state.dialogData;
    this.setState({ dialogVisible2: true, loadingDialog2: true });
    axios
      .get(CONST_API_URL + '/nav/nextoperations', {
        params: {
          order_no: target.order_no,
          operation_no: target.operation_no,
        },
      })
      .then((res) => {
        const data = res.data;
        this.setState({ nextOperationData: data, loadingDialog2: false });
      })
      .catch((error) => {
        this.setState({ loadingDialog2: false });
      });
  };

  handleOnClickRevertToPrevOp = async () => {
    const target = Array.isArray(this.state.dialogData)
      ? this.state.dialogData[0]
      : this.state.dialogData;
    this.setState({ dialogVisible6: true, loadingDialog6: true });
    axios
      .get(CONST_API_URL + '/nav/prevoperations', {
        params: {
          order_no: target.order_no,
          operation_no: target.operation_no,
        },
      })
      .then((res) => {
        const data = res.data;
        this.setState({ prevOperationData: data });
      })
      .catch((error) => {
        console.log('ERROR - handleOnClickRevertToPrevOp', error);
      })
      .finally(() => {
        this.setState({ loadingDialog6: false });
      });
  };

  handleCreateNextOperation = () => {
    this.setState({ loadingCreateNextOp: true });

    const navObjArr = this.state.nextOperationData;
    axios
      .post(CONST_API_URL + '/item/nextoperation', navObjArr)
      .then((res) => {
        this.toast.show({
          severity: 'success',
          summary: 'Confirmed',
          detail: `${res.data.message}`,
          life: 3000,
        });
        this.UpdateScheduled();
      })
      .catch((error) => {
        console.log('ERROR', error);
        this.toast.show({
          severity: 'error',
          summary: 'Error',
          detail: !!error.response.data.message
            ? error.response.data.message
            : `Failed to create a card(Unexpected Error)`,
          life: 3000,
        });
      })
      .finally(() =>
        this.setState({ dialogVisible2: false, loadingCreateNextOp: false })
      );
  };

  handleRevertToPrevOperation = () => {
    this.setState({ loadingRevertToPrevOp: true });

    const navObjArr = this.state.prevOperationData;
    axios
      .post(CONST_API_URL + '/item/prevoperation', {
        navObjArr,
        cardDataArr: this.state.dialogData,
      })
      .then((res) => {
        this.toast.show({
          severity: 'success',
          summary: 'Confirmed',
          detail: `${res.data.message}`,
          life: 3000,
        });
        this.UpdateScheduled();
      })
      .catch((error) => {
        this.toast.show({
          severity: 'error',
          summary: 'Error',
          detail: !!error.response.data.message
            ? error.response.data.message
            : `Failed to revert(Unexpected Error)`,
          life: 3000,
        });
      })
      .finally(() =>
        this.setState({
          dialogVisible: false,
          dialogVisible6: false,
          visible: false,
          loadingRevertToPrevOp: false,
        })
      );
  };

  handleMoveToTop = () => {
    const data = {
      // OriginColumn: this.props.varname,
      OriginColumnName: this.state.dialogData[0]?.workcenter, //this.props.workcenter,
      OriginIndex: this.state.dialogData[0]?.row_no,
      // TargetColumn: this.props.varname,
      TargetColumnName: this.state.dialogData[0]?.workcenter, //this.props.workcenter,
      TargetIndex: 0,
      method: 'drop',
    };
    console.log('handleMoveToTop', data);
    this.confirm(data);
    this.setState({ visible: false, dialogVisible3: false });
  };

  render() {
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />
        <Row style={{ height: '100%' }}>
          <Col
            xl={5}
            xxl={4}
            style={{ height: '100%', backgroundColor: '#dcdcdc' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div
                style={{
                  height: 'fit-contents',
                  maxHeight: '50%',
                  padding: '0.4rem',
                }}
              >
                <SearchFilters
                  placeholder="Search..."
                  searchData={this.state.search}
                  setSearchData={(data) => {
                    this.search(data);
                  }}
                  setInputFocus={(data) => {}}
                  inputFocus={true}
                  // disabled={this.props.varname === 'unscheduled' ? false : true}
                >
                  <Spin spinning={this.state.searchLoading}>
                    {/* {this.state.searchResultData.length > 0 ? (
                      <SearchResult
                        data={this.state.searchResultData}
                        style={{ width: '100%' }}
                      />
                    ) : this.state.keyword ? (
                      <p style={{ padding: '0 0.4rem' }}>No Record Found</p>
                    ) : (
                      <></>
                    )} */}
                    {this.state.searchResultData.length > 0 ? (
                      <SearchResult
                        data={this.state.searchResultData}
                        style={{ width: '100%' }}
                      />
                    ) : this.state.keyword && this.state.searchResultMessage ? (
                      <p style={{ padding: '0 0.4rem' }}>
                        {this.state.searchResultMessage}
                      </p>
                    ) : (
                      <></>
                    )}
                  </Spin>
                </SearchFilters>
              </div>
              <div style={{ height: '100%' }}>
                <Disabled
                  disabled={
                    this.props.user?.role?.toUpperCase() === 'SUPERVISOR'
                  }
                  style={{ height: '100%' }}
                >
                  <Unscheduled
                    data={
                      this.props.tab === 'Unscheduled'
                        ? this.state.unscheduled
                        : this.state.standard
                    }
                    name={
                      this.props.tab === 'Unscheduled'
                        ? 'Unscheduled'
                        : 'Standard Blocks'
                    }
                    parentCallback={this.confirm}
                    varname={
                      this.props.tab === 'Unscheduled'
                        ? 'unscheduled'
                        : 'standard'
                    }
                    makeStandard={() => this.setState({ visible: true })}
                    searchCallback={this.search}
                    passUnscheduledDate={this.UnscheduledDate}
                    clearUnscheduledDate={() =>
                      this.setState({ dateFilter: null }, () =>
                        this.MasterUpdate()
                      )
                    }
                    workcenters={this.props.workcenters}
                    // NOD={this.props.numofdays}
                    style={{ height: '100%' }}
                    spinning={this.state.unscheduledLoading}
                    displayItemLimit={this.props.displayItemLimit}
                    scrollToComponent={this.props.scrollToComponent}
                    workcenterList={this.props.workcenterList}
                  />
                </Disabled>
              </div>
            </div>
          </Col>
          <Col xl={19} xxl={20} style={{ height: '100%' }}>
            <div className="main__wrapper">
              {this.props.workcenters.length > 0 ? (
                this.props.workcenters.map((x, index) => (
                  <Column
                    name={x.workcenter_name}
                    // name={displayName}
                    workcenter={x.workcenter}
                    key={'Column' + x.workcenter}
                    parentCallback={this.confirm}
                    data={this.getWorkcenterData(x.workcenter)}
                    varname={index}
                    danger={x.workcenter.includes('DELETED')}
                    updateScheduled={() => this.UpdateScheduled()}
                    displayItemLimit={this.props.displayItemLimit}
                    spinning={this.state.scheduledLoading}
                    scrollToComponent={this.props.scrollToComponent}
                    workcenterList={this.props.workcenterList}
                    displayMoDetailModal={this.displayMoDetailModal}
                  />
                ))
              ) : (
                <Row style={{ padding: '2rem' }}>
                  <h1 style={{ color: 'var(--danger)' }}>
                    Please Select Division & Workcenters on the top menu
                  </h1>
                </Row>
              )}
            </div>
          </Col>
        </Row>

        <Dialog
          visible={this.state.visible}
          style={{
            width: '30vw',
            backgroundColor: 'white',
            zIndex: 60,
          }}
          modal={true}
          onHide={(e) => this.setState({ visible: false })}
          draggable={false}
          dismissableMask={true}
          contentStyle={{ height: '100%', padding: '0px' }}
          header="Create new Standard Block"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <InputText
              value={this.state.desc}
              placeholder={'Enter Description'}
              onChange={(e) => this.setState({ desc: e.target.value })}
              style={{ width: '80%', fontSize: '2em' }}
            />

            <Button
              className="p-button-outlined"
              label="Create Standard Block"
              style={{
                textAlign: 'center',
                fontSize: '1.4rem',
                margin: '1.4rem 0',
                // width: '55%',
                // marginTop: '5%',
              }}
              onClick={() => this.makeStandard()}
            />
          </div>
          {/* <h2
            onClick={() => this.makeStandard()}
            style={{
              textAlign: 'center',
              width: '55%',
              height: '5vh',
              backgroundColor: 'blue',
              color: 'white',
              lineHeight: '5vh',
              marginTop: '5%',
              marginLeft: '22.5%',
              cursor: 'pointer',
            }}
          >
            {' '}
            Create Standard Block
          </h2> */}
        </Dialog>

        {/*---------------------MO Detail Dialog-------------------------*/}
        <Dialog
          visible={this.state.dialogVisible}
          style={{
            // width: 'min-content',
            // height: '60vh',
            backgroundColor: 'white',
            zIndex: 60,
          }}
          modal={true}
          onHide={(e) => this.setState({ dialogVisible: false })}
          draggable={false}
          dismissableMask={true}
          contentStyle={{ height: '100%', padding: '0px' }}
          header={this.renderDialogHeader}
          footer={this.renderDialogFooter}
        >
          <DataTable
            value={
              Array.isArray(this.state.dialogData)
                ? this.state.dialogData
                : [this.state.dialogData]
            }
          >
            <PrimeColumn
              header="Status"
              body={(rowData) => {
                return !!!rowData.progress_status ? (
                  <>
                    <Brightness1Icon style={{ color: 'var(--gray)' }} />{' '}
                    <span>Awaiting</span>
                  </>
                ) : rowData.progress_status === 2 ||
                  rowData.progress_status === 4 ? (
                  <>
                    <Brightness1Icon style={{ color: 'var(--yellow)' }} />{' '}
                    <span>Paused</span>
                  </>
                ) : (
                  <>
                    <Brightness1Icon style={{ color: 'var(--green)' }} />{' '}
                    <span>Running</span>
                  </>
                );
              }}
              style={{ width: '8rem' }}
            />
            <PrimeColumn
              // field="operation_no"
              field="hspop_no"
              header="Op. No"
              style={{ width: '6rem' }}
            />
            <PrimeColumn
              header="Progress"
              body={(rowData) => `${rowData.part_count}/${rowData.quantity}`}
              style={{ width: '8rem' }}
            />
            <PrimeColumn
              header="Estimated time/hour"
              body={(rowData) =>
                rowData.isRunning
                  ? `${this.utcToLocale(rowData.estimated_completion_utc_time)}`
                  : `${(
                      rowData.setup_time +
                      rowData.run_time * (rowData.quantity - rowData.part_count)
                    ).toFixed(2)} hour`
              }
              style={{ width: '10rem' }}
            />
            <PrimeColumn
              header="Routing Description"
              body={(rowData) => {
                return (
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {rowData.routing_description}
                  </span>
                );
              }}
              style={{ width: '40rem' }}
            />
          </DataTable>
        </Dialog>
        {/*---------------------MO Detail Dialog End-------------------------*/}
        {/*---------------------Create Next Operation Dialog-------------------------*/}
        <OperationDialog
          visible={this.state.dialogVisible2}
          onHide={() => this.setState({ dialogVisible2: false })}
          orderNo={
            Array.isArray(this.state.dialogData)
              ? this.state.dialogData[0]?.order_no
              : this.state.dialogData?.order_no
          }
          tableData={this.state.nextOperationData}
          headingFragment={
            <h4>Do you want to create a card for below operations?</h4>
          }
          onClickYes={() => this.handleCreateNextOperation()}
          onClickNo={() => this.setState({ dialogVisible2: false })}
          disablePostButton={this.state.nextOperationData.length === 0}
          loadingLoadData={this.state.loadingDialog2}
          loadingProcess={this.state.loadingCreateNextOp}
        />
        {/*---------------------Create Next Operation Dialog End-------------------------*/}
        {/*---------------------Move to the top Dialog-------------------------*/}
        <ConfirmDialog
          visible={this.state.dialogVisible3}
          position="top"
          style={{
            backgroundColor: 'white',
            zIndex: 61,
          }}
          modal={true}
          onHide={() => this.setState({ dialogVisible3: false })}
          draggable={false}
          dismissableMask={true}
          contentStyle={{ height: '100%', padding: '0px' }}
          header={
            <>
              <h2>
                MO:{' '}
                {Array.isArray(this.state.dialogData)
                  ? this.state.dialogData[0]?.order_no
                  : this.state.dialogData?.order_no}
              </h2>
              <h4>Do you want to move this item to the top?</h4>
            </>
          }
          footer={
            <div>
              <Button
                label="No"
                icon="pi pi-times"
                onClick={() => this.setState({ dialogVisible3: false })}
                className="p-button-text"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                onClick={() => this.handleMoveToTop()}
                autoFocus
              />
            </div>
          }
        />
        {/*---------------------Move to the top Dialog End-------------------------*/}
        {/*---------------------Revert to Previous Operation Dialog-------------------------*/}
        <OperationDialog
          visible={this.state.dialogVisible6}
          onHide={() => this.setState({ dialogVisible6: false })}
          orderNo={
            Array.isArray(this.state.dialogData)
              ? this.state.dialogData[0]?.order_no
              : this.state.dialogData?.order_no
          }
          tableData={this.state.prevOperationData}
          headingFragment={
            <>
              <h4>
                Do you want to revert this card to below operations?
                <br />
                <span className="text-danger">
                  ※ This card will be moved to the target work center and timer
                  in Router will be reset
                </span>
              </h4>
            </>
          }
          onClickYes={() => this.handleRevertToPrevOperation()}
          onClickNo={() => this.setState({ dialogVisible6: false })}
          disablePostButton={this.state.prevOperationData.length === 0}
          loadingLoadData={this.state.loadingDialog6}
          loadingProcess={this.state.loadingRevertToPrevOp}
        />
        {/*---------------------Revert to Previous Operation Dialog End-------------------------*/}
        {/*---------------------Post History Dialog-------------------------*/}
        <PostHistoryDialog
          visible={this.state.dialogVisible4}
          onHide={() => this.setState({ dialogVisible4: false })}
          dialogData={this.state.dialogData}
        />
        {/*---------------------Post History Dialog End-------------------------*/}
        {/*---------------------Card Movement History Dialog-------------------------*/}
        <CardMovementHistoryDialog
          visible={this.state.dialogVisible5}
          onHide={() => this.setState({ dialogVisible5: false })}
          dialogData={this.state.dialogData}
        />
        {/*---------------------Card Movement History Dialog End-------------------------*/}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  displayItemLimit: state.displayItemLimit,
  dataRefreshIntervalMin: state.dataRefreshIntervalMin,
  scrollToComponent: state.scrollToComponent,
  user: state.user,
  updateItemFlag: state.updateItemFlag,
  division: state.division,
  workcenterList: state.workcenters,
});
export default connect(mapStateToProps)(withRouter(Page));
