import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Spin } from 'antd';
import { DataTable } from 'primereact/datatable';
import { Column as PrimeColumn } from 'primereact/column';

const OperationDialog = ({
  visible,
  onHide,
  orderNo,
  headingFragment,
  onClickYes,
  onClickNo,
  disablePostButton,
  loadingLoadData,
  loadingProcess,
  tableData,
}) => {
  return (
    <Dialog
      visible={visible}
      position="top"
      style={{
        width: '60vw',
        // height: '60vh',
        backgroundColor: 'white',
        zIndex: 61,
      }}
      modal={true}
      onHide={onHide}
      draggable={false}
      dismissableMask={true}
      contentStyle={{ height: '100%', padding: '0px' }}
      header={
        <>
          <h2>{`MO: ${orderNo}`}</h2>
          {headingFragment ?? null}
        </>
      }
      footer={
        <div>
          <Button
            label="No"
            icon="pi pi-times"
            onClick={onClickNo}
            className="p-button-text"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={onClickYes}
            autoFocus
            disabled={disablePostButton || loadingLoadData}
            loading={loadingProcess}
          />
        </div>
      }
    >
      <Spin spinning={loadingLoadData}>
        <DataTable value={tableData} style={{ marginBottom: '1.5rem' }}>
          <PrimeColumn field="hspop_no" header="Operation No" />
          <PrimeColumn field="No" header="Work center" />
          <PrimeColumn
            header="Routing Description"
            body={(rowData) => {
              return (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {rowData.routing_description}
                </span>
              );
            }}
            style={{ width: '40rem' }}
          />
        </DataTable>
      </Spin>
    </Dialog>
  );
};

export default OperationDialog;
