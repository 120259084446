import React from 'react';
import { Layout } from 'antd';
import Header from '../../components/header/generalheader.component';
import FooterComponent from '../../components/footer/footer.component';
import 'antd/dist/antd.css';
import { withRouter } from 'react-router-dom';
import Login from './login.component';
import './login.styles.css';
const { Content } = Layout;

const LoginPage = withRouter(() => {
  // const [form] = Form.useForm();
  // const onReset = () => {
  //   form.resetFields();
  // };
  // const onFinish = (values, history) => {
  //   axios
  //     .post(address + '/auth/login', {
  //       email: values.emailladress,
  //       password: values.password,
  //     })
  //     .then((result) => {
  //       if (
  //         result.data === 'Account not valid' ||
  //         result.data === 'Wrong Email address or Password'
  //       ) {
  //         notification.open({
  //           message: 'Wrong Email or Password',
  //           icon: <CloseOutlined style={{ color: '#e91010' }} />,
  //           duration: 7,
  //         });
  //       } else if (result.data === 'Account just Disabled') {
  //         notification.open({
  //           message:
  //             'Exceeded the Number of Incorrect tries! \r\n Your Account will be disabled for next 24 hours',
  //           icon: <CloseOutlined style={{ color: '#e91010' }} />,
  //           duration: 7,
  //         });
  //       } else if (result.data === 'Account Disabled') {
  //         notification.open({
  //           message: 'Account Disabled',
  //           icon: <CloseOutlined style={{ color: '#e91010' }} />,
  //           duration: 7,
  //         });
  //       } else {
  //         const today = new Date();
  //         const expirydate = new Date(
  //           new Date(today).setHours(today.getHours() + 12)
  //         );
  //         // var expirydate=new Date(new Date(today).setMinutes (today.getMinutes() + 3));
  //         localStorage.setItem('user-scheduler-Haven', expirydate);
  //         store.dispatch({
  //           type: CONNECT_WITH_SSO,
  //           user: result.data,
  //         });
  //       }
  //     });
  // };
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout style={{ height: 0 }}>
        <Layout className="site-layout" style={{ height: '100%' }}>
          <Content style={{ overflow: 'auto' }}>
            <Login />
          </Content>
        </Layout>
      </Layout>
      <FooterComponent />
    </Layout>
  );
  // return (
  //   <Layout style={{ minWidth: width, height: '100vh' }}>
  //     <Layout>
  //       <Header />
  //       <Layout className="site-layout">
  //         <Content
  //           style={{
  //             height: '100%',
  //             backgroundPosition: 'center',
  //             backgroundSize: 'cover',
  //             backgroundRepeat: 'no-repeat',
  //             backgroundImage:
  //               'url(' + 'https://i.ibb.co/bzmv7DK/haven-bkg-10.jpg' + ')',
  //           }}
  //         >
  //           <div
  //             className="site-layout-background"
  //             style={{
  //               height: '100%',
  //               display: 'flex',
  //               flexDirection: 'column',
  //               textAlign: 'center',
  //             }}
  //           >
  //             <p
  //               style={{
  //                 fontWeight: 'bold',
  //                 fontSize: '150%',
  //                 position: 'center',
  //                 color: 'white',
  //                 marginTop: '20px',
  //                 textShadow: '4px 4px 5px #000000',
  //               }}
  //             >
  //               {' '}
  //               Scheduling Application
  //             </p>
  //             <div
  //               style={{
  //                 textAlign: 'center',
  //                 marginTop: '2%',
  //                 marginBottom: '2%',
  //               }}
  //             >
  //               <center>
  //                 <div
  //                   className="login-form"
  //                   style={{
  //                     width: window.innerWidth >= 1024 ? '30vw' : '70vw',
  //                     height: '75%',
  //                     opacity: '0.99',
  //                   }}
  //                 >
  //                   <h1
  //                     style={{
  //                       color: 'black',
  //                       textAlign: 'center',
  //                       marginTop: '2%',
  //                     }}
  //                   >
  //                     Login
  //                   </h1>
  //                   {console.log(window.innerWidth)}
  //                   <Form
  //                     name="basic"
  //                     form={form}
  //                     style={{ padding: '10px 10px 10px 10px' }}
  //                     validateMessages={validateMessages}
  //                     labelCol={{ span: 'auto' }}
  //                     initialValues={{ remember: true }}
  //                     onFinish={onFinish}
  //                     // onFinishFailed={onFinishFailed}
  //                   >
  //                     <Form.Item
  //                       hasFeedback
  //                       label="Email address"
  //                       name="emailladress"
  //                       rules={[{ required: true, type: 'email' }]}
  //                     >
  //                       <Input placeholder="input emaill adress" />
  //                     </Form.Item>

  //                     <Form.Item
  //                       label="Password"
  //                       name="password"
  //                       rules={[{ required: true }]}
  //                     >
  //                       <Input.Password placeholder="input password" />
  //                     </Form.Item>
  //                     <Form.Item>
  //                       <a
  //                         style={{ float: 'left' }}
  //                         className="login-form-forgot"
  //                         href={() => false}
  //                         onClick={() => UpdatePassword(history)}
  //                       >
  //                         Update/Forget password
  //                       </a>
  //                       <a
  //                         style={{ float: 'right' }}
  //                         className="login-form-register"
  //                         href={() => false}
  //                         onClick={() => Register(history)}
  //                       >
  //                         Register
  //                       </a>
  //                     </Form.Item>
  //                     <Form.Item>
  //                       <Space>
  //                         <Button shape="round" type="danger" htmlType="submit">
  //                           Login
  //                         </Button>
  //                         <Button
  //                           shape="round"
  //                           htmlType="button"
  //                           onClick={onReset}
  //                         >
  //                           Reset
  //                         </Button>
  //                       </Space>
  //                     </Form.Item>
  //                   </Form>
  //                 </div>
  //               </center>
  //             </div>
  //             <div style={{ marginTop: 'auto' }}>
  //               <FooterComponent color="white" />
  //             </div>
  //           </div>
  //         </Content>
  //       </Layout>
  //     </Layout>
  //   </Layout>
  // );
});

export default LoginPage;
