import moment from 'moment';

/*
 * Get the amount of time between a start and end time
 *   @param   {moment}  start     The start time
 *   @param   {moment}  end       The end time
 *   @return  {string}  duration  The amount of time in hours
 */
function getDuration(start, end) {
  var duration = 0;
  duration = moment.duration(moment(end).diff(start));
  duration = duration.asHours();

  return duration;
}

/*
 * Checks if the specified amount of time has been passed
 *   @param   {moment}  start     The start time
 *   @param   {moment}  end       The end time
 *   @param   {number}  limit     The upper limit in time
 *   @return  {string}  duration  The amount of time in hours
 */
export function isTimeLimitPassed(start, end, limit) {
  var duration = getDuration(start, end);

  if (duration > limit) {
    return true;
  } else {
    return false;
  }
}
