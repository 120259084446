import React from 'react';
import { Layout } from 'antd';
import Header from '../../components/header/generalheader.component';
import FooterComponent from '../../components/footer/footer.component';
import 'antd/dist/antd.css';
import ResetPassword from './reset-password.component';
import './reset-password.styles.css';
const { Content } = Layout;

const ResetPasswordPage = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout style={{ height: 0 }}>
        <Layout className="site-layout" style={{ height: '100%' }}>
          <Content style={{ overflow: 'auto' }}>
            <ResetPassword />
          </Content>
        </Layout>
      </Layout>
      <FooterComponent />
    </Layout>
  );
};

export default ResetPasswordPage;
