import React from 'react';
import { Button, Navbar, Nav } from 'react-bootstrap';
// import logo from '../../assets/logo-haven-steel.png';
import logo from '../../assets/Haven_logo_Final_White-01.svg';
import { connect } from 'react-redux';
import './header.styles.css';
import { withRouter } from 'react-router-dom';
import { store } from '../../redux/store';
import {
  COLUMN_INFO,
  DISCONNECT,
  DIVISION,
  UPDATE_SCHEDULED_ITEM,
  UPDATE_UNSCHEDULED_ITEM,
  WORK_CENTERS,
} from '../../redux/types';
import { Avatar, Menu, Dropdown, Drawer, Spin } from 'antd';
import { Dropdown as PrimeDropDown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import axios from 'axios';
import HelpButton from './helpButton.component';
import Settings from './settings.component';
import { CONST_API_URL, DEV_MODE } from '../../appconfig';
import SyncInfoWithButton from './syncinfowithbutton.component';
import pjson from '../../../package.json';

async function disconnectWithSSO(history) {
  console.log('DisconnectWithSSO');

  store.dispatch({
    type: DISCONNECT,
  });
  history.push('/');
  localStorage.removeItem('user-scheduler-Haven');
}
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [],
      location: [],
      tabsSelected: '',
      NAVCompany: '',
      selectedWorkcenters: null,
      displayItemLimit: [],
      displayItemLimitSelected: null,
      dataRefreshInterval: [],
      dataRefreshIntervalSelected: null,
      // dataRefreshIntervalMin: this.props.dataRefreshIntervalMin || 5,
      settingVisible: false,

      allWorkCenters: [],
      // locationWorkCenters: [],
      workcentersGroup: [],
      division: [],

      // --- Loading ---
      loadingDivision: false,
      loadingLocation: false,
      loadingWorkcenter: false,
    };
    this.createWorkCentersGroup = this.createWorkCentersGroup.bind(this);
    this.updateWorkcenters = this.updateWorkcenters.bind(this);
  }

  componentDidMount() {
    // this.updateWorkcenters();
    // this.getDropdown('division');
    // if (this.props.division?.code === 'HAVEN') {
    //   this.getDropdown('location');
    // }
    this.fetchWorkCenters();
    this.fetchDivisionDropdown();
    this.fetchLocationDropdown();

    //----------Settings-----------
    this.getDropdown('dataRefreshInterval');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.division?.code === 'HAVEN') {
      if (
        this.state.allWorkCenters?.length > 0 &&
        this.state.location?.length > 0 &&
        (prevState.allWorkCenters?.length === 0 ||
          prevState.location?.length === 0)
      ) {
        //--- Update workcenter list with first location as default ---
        this.updateLocation(this.state.location);
      }
    } else {
      if (
        this.state.allWorkCenters?.length > 0 &&
        prevState.allWorkCenters?.length === 0
      ) {
        console.log('Componentdidupdate-McKenzie');
        this.updateLocation();
      }
    }
  }

  fetchDivisionDropdown = async () => {
    try {
      this.setState({ loadingDivision: true });
      await this.getDropdown('division');
    } catch (error) {
      console.log('ERROR - fetchDivisionDropdown', error);
    } finally {
      this.setState({ loadingDivision: false });
    }
  };

  fetchLocationDropdown = async () => {
    try {
      this.setState({ loadingLocation: true });
      await this.getDropdown('location');
    } catch (error) {
      console.log('ERROR - fetchLocationDropdown', error);
    } finally {
      this.setState({ loadingLocation: false });
    }
  };

  fetchWorkCenters = async () => {
    try {
      this.setState({ loadingWorkcenter: true });
      await this.updateWorkcenters();
    } catch (error) {
      console.log('ERROR - fetchWorkCenters', error);
    } finally {
      this.setState({ loadingWorkcenter: false });
    }
  };

  updateWorkcenters = async () => {
    const res = await axios.get(CONST_API_URL + '/nav/workcenter', {
      params: { userId: this.props.user?.id },
    });

    if (res?.data) {
      // this.setState({ allWorkCenters: res.data });
      // store.dispatch({
      //   type: WORK_CENTERS,
      //   workcenters: res.data,
      // });
      const usePersonalSort = res.data?.usePersonalSort;
      const data = usePersonalSort ? res.data.personal : res.data.system;
      this.setState({ allWorkCenters: data });
      store.dispatch({
        type: WORK_CENTERS,
        workcenters: data,
      });
    }
  };

  updateLocation = (locations = undefined) => {
    let filteredWorkCenters = [];
    if (locations) {
      filteredWorkCenters = this.state.allWorkCenters.filter((obj) => {
        if (obj.hidden) {
          return false;
        }
        const recordLocation = obj.workcenter_location;
        return locations.some((location) => location.code === recordLocation);
      });

      this.setState(
        {
          workcentersGroup: this.createWorkCentersGroup(
            locations,
            filteredWorkCenters
          ),
        },
        () =>
          store.dispatch({
            type: COLUMN_INFO,
            columnInfo: {
              ...this.props.columnInfo,
              location: locations,
              workcenter: filteredWorkCenters,
            },
          })
      );
    } else if (this.props.division?.code) {
      filteredWorkCenters = this.state.allWorkCenters.filter(
        (obj) => obj.division === this.props.division.code && !obj.hidden
      );
      this.setState(
        {
          workcentersGroup: [
            { group: this.props.division.code, items: filteredWorkCenters },
          ],
        },
        () =>
          store.dispatch({
            type: COLUMN_INFO,
            columnInfo: {
              ...this.props.columnInfo,
              location: null,
              workcenter: filteredWorkCenters,
            },
          })
      );
    } else {
      filteredWorkCenters = this.state.allWorkCenters;
    }
  };

  getDropdown = async (dropdown) => {
    const res = await axios.get(CONST_API_URL + '/config/dropdown', {
      params: { dropdown },
    });

    const data = res.data;
    this.setState({ [`${dropdown}`]: data });

    if (dropdown === 'location') {
      this.setState((prev) => ({
        selectedWorkcenters: null,
      }));
      // if (this.props.division?.code === 'HAVEN') {
      //   this.updateLocation([data[0]]);
      // } else {
      //   this.updateLocation();
      // }
    } else if (dropdown === 'displayItemLimit') {
      if (this.props.displayItemLimit >= 0) {
        const dataObj = data.find(
          (obj) => Number(obj.code) === this.props.displayItemLimit
        );
        if (dataObj) {
          this.setState({ displayItemLimitSelected: dataObj });
        }
      }
    } else if (dropdown === 'dataRefreshInterval') {
      if (!Number.isNaN(this.props.dataRefreshIntervalMin)) {
        const dataObj = data.find(
          (obj) => Number(obj.code) === this.props.dataRefreshIntervalMin
        );
        if (dataObj) {
          this.setState({ dataRefreshIntervalSelected: dataObj });
        }
      }
    } else {
      this.setState({ [`${dropdown}Selected`]: data[0] }, () => {
        // if (dropdown === 'tabs') {
        //   this.props.passTab(data[0]);
        // }
      });
    }
  };

  onChangeHandler = (e) => {
    this.setState((prev) => ({
      // locationSelected: e.value,
      selectedWorkcenters: null,
    }));
    // this.filterWorkcenters([]);
    this.updateLocation(e.value);
  };

  onChangeWorkcenterHandler = (e) => {
    this.setState({ selectedWorkcenters: e.value });

    store.dispatch({
      type: COLUMN_INFO,
      columnInfo: {
        ...this.props.columnInfo,
        workcenter: e.value,
      },
    });
  };

  createWorkCentersGroup = (locationSelected, locationWorkCenters) => {
    if (!!!locationSelected) return [];

    const result = [];

    const mappedLocationSelected = locationSelected
      .map((location) => location.name)
      .sort();
    for (const name of mappedLocationSelected) {
      result.push({ group: name, items: [] });
    }

    for (let i = 0; i < locationWorkCenters.length; i++) {
      const target = locationWorkCenters[i];
      let locationIndex = -1;
      if (target.workcenter_location === 'North') {
        locationIndex = result.findIndex((obj) => obj.group === 'North Plant');
      } else if (target.workcenter_location === 'South') {
        locationIndex = result.findIndex((obj) => obj.group === 'South Plant');
      } else {
        console.log(
          '##### Neither North or South workcenter_location is detected #####'
        );
      }
      if (locationIndex !== -1) {
        result[locationIndex].items.push(target);
      }
    }
    return result;
  };

  onDivisionChangeHandler = (e) => {
    // console.log('onDivisionChangeHandler e:', e);
    store.dispatch({
      type: DIVISION,
      division: e.value,
    });
    store.dispatch({
      type: UPDATE_SCHEDULED_ITEM,
      updateScheduledItem: true,
    });
    store.dispatch({
      type: UPDATE_UNSCHEDULED_ITEM,
      updateUnscheduledItem: true,
    });

    const filteredWorkCenters = this.state.allWorkCenters.filter(
      (obj) => obj.division === e.value.code && !obj.hidden
    );

    const workcenterGroup =
      e.value.code === 'HAVEN'
        ? this.createWorkCentersGroup(this.state.location, filteredWorkCenters)
        : [{ group: e.value.name, items: filteredWorkCenters }];

    this.setState(
      {
        workcentersGroup: workcenterGroup,
      },
      () =>
        store.dispatch({
          type: COLUMN_INFO,
          columnInfo: {
            ...this.props.columnInfo,
            location: e.value.code === 'HAVEN' ? this.state.location : null,
            workcenter: filteredWorkCenters,
          },
        })
    );
  };

  // buildWcOptionTemplate = (option) => {
  //   return `${option.workcenter_name}${
  //     option.workcenter !== option.workcenter_name
  //       ? `(${option.workcenter})`
  //       : ''
  //   }`;
  // };

  render() {
    // console.log(this.props.user);
    return (
      <Navbar style={{ backgroundColor: 'black' }} expand="lg">
        <Navbar.Brand href={'/'}>
          <div
            style={{
              width: '350px',
              height: '100%',
              textAlign: 'center',
              margin: '-10px 0',
            }}
          >
            <img
              alt=""
              src={logo}
              width="100%"
              className="d-inline-block align-top"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Spin spinning={this.state.loadingDivision}>
                <PrimeDropDown
                  value={this.props.division}
                  options={this.state.division}
                  onChange={this.onDivisionChangeHandler}
                  optionLabel="name"
                  placeholder="Select Division"
                />
              </Spin>
              {this.props.division.code === 'HAVEN' && (
                <Spin spinning={this.state.loadingLocation}>
                  <MultiSelect
                    // value={this.state.locationSelected}
                    value={this.props.columnInfo.location}
                    options={this.state.location}
                    onChange={this.onChangeHandler}
                    optionLabel="name"
                    placeholder="Select Location"
                    maxSelectedLabels={2}
                    style={{
                      textAlign: 'center',
                      marginLeft: '1vw',
                    }}
                  />
                </Spin>
              )}
              <Spin spinning={this.state.loadingWorkcenter}>
                <MultiSelect
                  filter
                  value={this.props.columnInfo.workcenter}
                  options={this.state.workcentersGroup}
                  optionLabel="workcenter_name"
                  optionGroupLabel="group"
                  optionGroupChildren="items"
                  placeholder="Select Workcenters"
                  // itemTemplate={this.buildWcOptionTemplate}
                  onChange={this.onChangeWorkcenterHandler}
                  maxSelectedLabels={3}
                  style={{
                    textAlign: 'center',
                    marginLeft: '1vw',
                  }}
                />
              </Spin>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Text
            style={{
              color: 'lightgray',
              marginRight: '20px',
              border: '1px solid lightgray',
              borderRadius: '0.5rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
            }}
          >
            <SyncInfoWithButton latestSyncDate={this.props.latestSync} />
          </Navbar.Text>
          <Navbar.Text
            style={{
              color: 'white',
              marginRight: '20px',
            }}
          >
            {DEV_MODE ? 'TEST - V' : 'V'}
            {pjson.version}
          </Navbar.Text>
          {this.props.user ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => disconnectWithSSO(this.props.history)}
                    >
                      Sign out
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Avatar
                style={{
                  backgroundColor: '#1E90FF',
                  marginRight: '20px',
                }}
              >
                {/* {this.props.user.adUser.hasOwnProperty('givenName') ? (this.props.user.adUser.hasOwnProperty('sn') ? this.props.user.adUser.givenName[0].charAt(0) + this.props.user.adUser.sn[0].charAt(0) : this.props.user.adUser.givenName[0].charAt(0) + '-') : (this.props.user.adUser.hasOwnProperty('sn') ? '-' + this.props.user.adUser.sn[0].charAt(0) : '--')}
        {} */}
                {this.props.user.hasOwnProperty('fullName')
                  ? this.props.user.fullName.charAt(0).toUpperCase() +
                    (this.props.user.fullName.match(/\b(\w)/g)[1] || '')
                  : '--'}
              </Avatar>
            </Dropdown>
          ) : (
            ''
          )}

          <div style={{ marginRight: '20px' }}>
            <HelpButton />
          </div>

          <div
            onClick={() => this.setState({ settingVisible: true })}
            style={{ color: 'white', cursor: 'pointer' }}
          >
            <SettingsOutlinedIcon style={{ fontSize: '2rem' }} />
          </div>
        </Navbar.Collapse>
        <Drawer
          title="Settings"
          placement="right"
          width={'30vw'}
          visible={this.state.settingVisible}
          onClose={() => this.setState({ settingVisible: false })}
        >
          <Settings
            navCompany={this.props.navCompany}
            navEnvironment={this.props.navEnvironment}
            reloadWorkCenters={async () => {
              await this.updateWorkcenters();
              this.onDivisionChangeHandler({ value: this.props.division });
            }}
          />
        </Drawer>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  displayItemLimit: state.displayItemLimit,
  dataRefreshIntervalMin: state.dataRefreshIntervalMin,
  columnInfo: state.columnInfo,
  division: state.division,
});
export default connect(mapStateToProps)(withRouter(Header));
