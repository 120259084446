import { Button, Navbar } from 'react-bootstrap';
import logo from '../../assets/Haven_logo_Final_White-01.svg';
import { connect } from 'react-redux';
import './header.styles.css';
import { withRouter } from 'react-router-dom';
import { store } from '../../redux/store';
import { Avatar, Menu, Dropdown } from 'antd';
import { DISCONNECT } from '../../redux/types';
import { DEV_MODE } from '../../appconfig';
import pjson from '../../../package.json';

async function disconnectWithSSO(history) {
  console.log('DisconnectWithSSO');

  store.dispatch({
    type: DISCONNECT,
  });
  history.push('/');
  localStorage.removeItem('user-scheduler-Haven');
}

const Header = withRouter(({ history, user }) => (
  <Navbar style={{ backgroundColor: 'black' }} expand="sm">
    <Navbar.Brand href="/">
      <div
        style={{
          width: '350px',
          height: '100%',
          textAlign: 'center',
          margin: '-10px 0',
        }}
      >
        <img
          alt=""
          src={logo}
          width="100%"
          className="d-inline-block align-top"
        />
      </div>
    </Navbar.Brand>

    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse className="justify-content-end">
      <Navbar.Text
        style={{
          color: 'white',
          marginRight: '20px',
        }}
      >
        {DEV_MODE ? 'TEST - V' : 'V'}
        {pjson.version}
      </Navbar.Text>
      {user ? (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => disconnectWithSSO(history)}
                >
                  Sign out
                </Button>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Avatar
            style={{
              backgroundColor: '#1E90FF',
              marginRight: '20px',
            }}
          >
            {/* {user.adUser.hasOwnProperty('givenName') ? (user.adUser.hasOwnProperty('sn') ? user.adUser.givenName[0].charAt(0) + user.adUser.sn[0].charAt(0) : user.adUser.givenName[0].charAt(0) + '-') : (user.adUser.hasOwnProperty('sn') ? '-' + user.adUser.sn[0].charAt(0) : '--')}
        {} */}
            {user.hasOwnProperty('fullName')
              ? user.fullName.charAt(0).toUpperCase() +
                (user.fullName.match(/\b(\w)/g)[1] || '')
              : '--'}
          </Avatar>
        </Dropdown>
      ) : (
        ''
      )}
    </Navbar.Collapse>
  </Navbar>
));

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(Header);

// <Button size="sm" variant="danger" onClick={() => disconnectWithSSO(history)}>Sign out</Button>
// <NavDropdown.Item style={{ size: '10px' }}></NavDropdown.Item>
