import { useEffect, useState } from 'react';

const HeightPreservingItem = ({ children, ...props }) => {
  const [size, setSize] = useState(0);
  const knownSize = props['data-known-size'];
  useEffect(() => {
    setSize((prevSize) => {
      // eslint-disable-next-line eqeqeq
      return knownSize == 0 ? prevSize : knownSize; //0 could be string so use == instead of ===
    });
  }, [knownSize]);

  return (
    <div
      {...props}
      className="height-preserving-container"
      style={{
        '--child-height': `${size}px`,
      }}
    >
      {children}
    </div>
  );
};

export default HeightPreservingItem;
