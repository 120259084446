import { Row, Divider, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { CONST_API_URL } from '../../appconfig';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
// import { OrderList } from 'primereact/orderlist';
import { PickList } from 'primereact/picklist';
import './workcentersetupform.styles.css';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { connect } from 'react-redux';
// import _ from 'lodash';

function convertWcData(dataArr) {
  const hidden = [];
  const visible = [];

  for (const obj of dataArr) {
    if (obj.hidden) {
      hidden.push(obj);
    } else {
      visible.push(obj);
    }
  }

  return { hidden, visible };
}

const WorkCenterSetupForm = (props) => {
  const [option, setOption] = useState('system');
  // const [systemWcData, setSystemWcData] = useState([]);
  // const [originalSystemWcData, setOriginalSystemWcData] = useState([]);
  // const [personalWcData, setPersonalWcData] = useState([]);
  // const [disablePersonal, setDisablePersonal] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [hiddenSystemWcData, setHiddenSystemWcData] = useState([]);
  const [visibleSystemWcData, setVisibleSystemWcData] = useState([]);
  const [hiddenPersonalWcData, setHiddenPersonalWcData] = useState([]);
  const [visiblePersonalWcData, setVisiblePersonalWcData] = useState([]);

  const toast = useRef(null);

  // const systemWcUpdatedFg = !_.isEqual(systemWcData, originalSystemWcData);

  useEffect(() => {
    fetchData(props.user?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.division, props.user]);

  const fetchData = async (userId) => {
    try {
      setLoadingData(true);
      const response = await axios.get(CONST_API_URL + '/nav/workcenter', {
        params: {
          userId,
          division: props.division,
        },
      });

      if (response?.data) {
        const { system, personal, usePersonalSort } = response.data;
        // setSystemWcData(system || []);
        // setOriginalSystemWcData(system || []);
        // if (!personal || personal.length < 1) {
        //   setDisablePersonal(true);
        // } else {
        //   setPersonalWcData(personal);
        // }
        if (system) {
          const { hidden: systemHidden, visible: systemVisible } =
            convertWcData(system);
          setHiddenSystemWcData(systemHidden);
          setVisibleSystemWcData(systemVisible);
        }
        if (personal) {
          const { hidden: personalHidden, visible: personalVisible } =
            convertWcData(personal);
          setHiddenPersonalWcData(personalHidden);
          setVisiblePersonalWcData(personalVisible);
        } else if (!!!personal && system) {
          // setHiddenPersonalWcData(system);
          setVisiblePersonalWcData(
            system.map((row) => ({
              ...row,
              sort_id: null,
            }))
          );
        }

        setOption(usePersonalSort ? 'personal' : 'system');
        setApiError(null);
      } else {
        setApiError('Unexpected Error occurred (E1)');
      }
    } catch (error) {
      console.log('ERROR - WorkCenterSetupForm_fetchData', error);
      setApiError('Unexpected Error occurred (E2)');
    } finally {
      setLoadingData(false);
    }
  };

  // const onClickCopyButton = () => {
  //   setPersonalWcData(
  //     systemWcData.map((row) => ({
  //       ...row,
  //       sort_id: personalWcData[0]?.sort_id,
  //       description: personalWcData[0]?.description,
  //     }))
  //   );
  // };

  const handleChangeOption = (value) => {
    setOption(value);

    // if (value === 'personal') {
    //   setDisablePersonal(false);

    //   if (personalWcData.length < 1) {
    //     setPersonalWcData(
    //       systemWcData.map((row) => ({ ...row, sort_id: null }))
    //     );
    //   }
    // }
  };

  const handleSave = async () => {
    function displayErrorToast() {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: 'Update has failed, please try again',
        life: 5000,
      });
    }

    try {
      setLoadingSave(true);
      const updatedSystemWcData = visibleSystemWcData
        .map((obj) => ({ ...obj, hidden: false }))
        .concat(hiddenSystemWcData.map((obj) => ({ ...obj, hidden: true })));
      const updatedPersonalWcData = visiblePersonalWcData
        .map((obj) => ({ ...obj, hidden: false }))
        .concat(hiddenPersonalWcData.map((obj) => ({ ...obj, hidden: true })));
      const usePersonalSort = option === 'personal';
      const response = await axios.post(
        CONST_API_URL + '/workcenter/updatesort',
        {
          ...(usePersonalSort
            ? { personal: updatedPersonalWcData }
            : { system: updatedSystemWcData }),
          userId: props.user.id,
          usePersonalSort: usePersonalSort,
        }
        // {
        //   system: systemWcData,
        //   personal: personalWcData,
        //   userId: props.user.id,
        //   usePersonalSort: option === 'personal',
        // }
      );
      // console.log({ response });
      if (response?.status === 200) {
        props?.reloadWorkCenters?.();
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Update was completed successfully',
          life: 5000,
        });
      } else {
        displayErrorToast();
      }
    } catch (error) {
      console.log('ERROR - handleSave', error);
      displayErrorToast();
    } finally {
      setLoadingSave(false);
    }
  };

  // const onClickHideUnhide = (item) => {
  //   const _personalWcData = [...personalWcData];
  //   const index = _personalWcData.indexOf(item);
  //   const selectedItem = _personalWcData.splice(index, 1)[0];
  //   selectedItem.hidden = !selectedItem.hidden;
  //   if (item.hidden === true) {
  //     setPersonalWcData([..._personalWcData, selectedItem]);
  //   } else {
  //     const targetIdx = _personalWcData.findIndex((obj) => obj.hidden === true);
  //     if (targetIdx > -1) {
  //       _personalWcData.splice(targetIdx, 0, selectedItem);
  //       setPersonalWcData([..._personalWcData]);
  //     } else {
  //       setPersonalWcData([..._personalWcData, selectedItem]);
  //     }
  //   }
  // };

  // const systemItemTemplate = (item) => (
  const itemTemplate = (item) => (
    <div className="flex align-items-center p-2 w-full flex-wrap">
      <h5 className="mb-2">{item.workcenter_name}</h5>
    </div>
  );

  // const personalItemTemplate = (item) => {
  //   const hidden = item.hidden;

  //   return hidden ? (
  //     <div className="workcenterorderform__orderlist-personalitem orderlistitem-disabled">
  //       <h5>{item.workcenter_name}</h5>
  //       <Button
  //         label={'Unhide'}
  //         className={`p-button-outlined p-button-secondary`}
  //         onClick={() => onClickHideUnhide(item)}
  //       />
  //     </div>
  //   ) : (
  //     <div className="workcenterorderform__orderlist-personalitem">
  //       <h5>{item.workcenter_name}</h5>
  //       <Button
  //         label={'Hide'}
  //         className={`p-button-outlined p-button-danger`}
  //         onClick={() => onClickHideUnhide(item)}
  //       />
  //     </div>
  //   );
  // };

  return (
    <div className="workcenterorderform__wrapper">
      <Toast ref={toast} />
      {apiError && (
        <div className="workcenterorderform__error">
          Unexpected Error occurred during loading user data
          <br />
          Detail: {apiError}
          <br />
          <Button
            className="p-button-outlined p-button-danger"
            label="Try Again"
            onClick={() => {
              fetchData(props.user?.id);
            }}
          />
        </div>
      )}

      <div className="workcenterorderform__option-container">
        <div className="option__label">Select Sort Option</div>
        <div className="option__radio-container">
          <div>
            <RadioButton
              inputId="option1"
              name="workcenterOption"
              value="system"
              onChange={(e) => handleChangeOption(e.value)}
              checked={option === 'system'}
            />
            <label className="ml-2">System (Default)</label>
          </div>
          <div>
            <RadioButton
              inputId="option2"
              name="workcenterOption"
              value="personal"
              onChange={(e) => handleChangeOption(e.value)}
              checked={option === 'personal'}
            />
            <label className="ml-2">Personal</label>
          </div>
        </div>
      </div>

      <Spin spinning={loadingData}>
        <div className="workcenterorderform__orderlist-container">
          <div className="orderlistform__label">Customize Work Center Sort</div>
          <Row {...(loadingSave && { className: 'disabled' })}>
            <div className="orderlist-container">
              <Divider>{option === 'system' ? 'System' : 'Personal'}</Divider>
              <PickList
                source={
                  option === 'system'
                    ? hiddenSystemWcData
                    : hiddenPersonalWcData
                }
                target={
                  option === 'system'
                    ? visibleSystemWcData
                    : visiblePersonalWcData
                }
                itemTemplate={itemTemplate}
                onChange={(e) => {
                  if (option === 'system') {
                    setHiddenSystemWcData(e.source);
                    setVisibleSystemWcData(e.target);
                  } else {
                    setHiddenPersonalWcData(e.source);
                    setVisiblePersonalWcData(e.target);
                  }
                }}
                sourceHeader="Hidden"
                targetHeader="Visible"
              />
            </div>
            {/* <div className="orderlist-container">
              <Divider>System</Divider>
              <OrderList
                {...(option !== 'system' && { className: 'disabled' })}
                value={systemWcData}
                itemTemplate={systemItemTemplate}
                dragdrop
                onChange={(e) => setSystemWcData(e.value)}
              />
            </div>
            <div className="orderlist__copybutton-container">
              <Button
                icon="pi pi-angle-double-right"
                className="p-button-outlined"
                onClick={() => onClickCopyButton()}
                disabled={disablePersonal || option !== 'personal'}
              />
            </div>
            <div className="orderlist-container">
              <Divider>
                {disablePersonal ? 'Personal (Disabled)' : 'Personal'}
              </Divider>
              <OrderList
                {...((disablePersonal || option !== 'personal') && {
                  className: 'disabled',
                })}
                value={personalWcData}
                itemTemplate={personalItemTemplate}
                dragdrop
                onChange={(e) => setPersonalWcData(e.value)}
              />
            </div> */}
          </Row>
        </div>
      </Spin>

      {/* {systemWcUpdatedFg && (
        <div className="mb-2">
          <p className="text-danger text-right">
            ※ Change on system setting is detected, your change will be applied
            to other users using system setting.
          </p>
        </div>
      )} */}

      <div className="workcenterorderform__button-container">
        <Button
          label="Cancel"
          className="p-button-outlined p-button-danger"
          onClick={() => props.onCancel?.()}
        />
        <Button
          label="Save and Apply"
          className="p-button-outlined"
          onClick={() => handleSave()}
          loading={loadingSave}
          disabled={loadingData}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  division: state.division?.code,
});
export default connect(mapStateToProps)(withRouter(WorkCenterSetupForm));
