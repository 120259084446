import React from 'react';
import { Input, Icon, Divider } from 'semantic-ui-react';
import './jobsearch.style.css';

function SearchItem({ text, inputtedValue, clear, index }) {
  return (
    <li key={`${inputtedValue}-${index}`} className="searchable">
      <span>{`${text}:`}</span>
      &nbsp;
      {inputtedValue}
      <button
        onClick={() => {
          clear();
        }}
        className="clear-button"
        type="button"
      >
        <Icon name="x" />
      </button>
    </li>
  );
}

export default function SearchFilters({
  setSearchData,
  searchData,
  disabled,
  loading,
  placeholder,
  searchables,
  children,
  style,
  inputFocus,
  setInputFocus,
}) {
  const [internalValue, setInternalValue] = React.useState(
    searchData.inputValue
  );
  const [selectedSearchables, setSelectedSearchables] = React.useState(
    searchData.selectedSearchables
  );

  const [dividerVisible, setDividerVisible] = React.useState(false);

  const inputEl = React.useRef(null);

  return (
    <div className="search-filter" style={style}>
      <div className="bar-contain">
        <div className="search-bar">
          <ul className="search-contain">
            {selectedSearchables.map((s, i) => {
              return (
                <SearchItem
                  clear={() => {
                    const updatedSearchable = selectedSearchables.filter(
                      (f) =>
                        !(
                          f.value === s.value &&
                          f.inputtedValue === s.inputtedValue
                        )
                    );
                    setSelectedSearchables(updatedSearchable);
                    setSearchData({
                      inputValue: '',
                      selectedSearchables: updatedSearchable,
                    });
                    inputEl.current?.focus();
                  }}
                  index={i}
                  key={`${s.text}-${s.value}-${s.inputtedValue}`}
                  text={s.text}
                  value={s.value}
                  inputtedValue={s.inputtedValue}
                />
              );
            })}
            <li key="input" className="input">
              <Input
                id="search-input"
                loading={loading}
                disabled={disabled}
                onFocus={() => setInputFocus(true)}
                // onBlur={() => {
                //   setSearchData({
                //     inputValue: internalValue,
                //     selectedSearchables,
                //   });
                // }}
                ref={inputEl}
                autoComplete="off"
                transparent
                onChange={(e, { value }) => {
                  setInternalValue(value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSearchData({
                      inputValue: internalValue,
                      selectedSearchables,
                    });

                    setInputFocus(false);
                    setDividerVisible(true);
                  }
                }}
                placeholder={placeholder}
                value={internalValue}
              />
            </li>
          </ul>

          {(selectedSearchables.length > 0 || !!internalValue) && (
            <button
              onClick={() => {
                setInternalValue('');
                setSelectedSearchables([]);
                setSearchData({ inputValue: '', selectedSearchables: [] });
                setInputFocus(false);
                setDividerVisible(false);
              }}
              className="clear-button"
              type="button"
            >
              <Icon name="x" />
            </button>
          )}
          <button
            onClick={() => {
              setSearchData({ inputValue: internalValue, selectedSearchables });
              setInputFocus(false);
              setDividerVisible(true);
            }}
            className="clear-button"
            type="button"
          >
            <Icon name="search" />
          </button>
        </div>
        {/* {!!searchables &&
          searchables.length > 0 &&
          internalValue.length > 0 &&
          inputFocus && (
            <div className="suggestions" ref={suggestionsEl}>
              {searchables.map((s, idx) => {
                return (
                  <React.Fragment key={s.key}>
                    <button
                      onClick={() => {
                        setInputFocus(false);

                        const isUniqueSearch = selectedSearchables.every(
                          (searchable) =>
                            searchable.inputtedValue !== internalValue ||
                            (searchable.inputtedValue === internalValue &&
                              searchable.value !== s.value)
                        );

                        if (isUniqueSearch) {
                          setSelectedSearchables([
                            ...selectedSearchables,
                            {
                              text: s.text,
                              value: s.value,
                              inputtedValue: internalValue,
                            },
                          ]);
                        }

                        setInternalValue('');
                        inputEl.current?.focus();
                      }}
                      type="button"
                    >
                      {s.text}
                    </button>
                    {idx + 1 < searchables.length && <Divider fitted />}
                  </React.Fragment>
                );
              })}
            </div>
          )} */}
      </div>
      {dividerVisible && (
        <Divider
          style={{ marginTop: '3px', marginBottom: '3px', width: '100%' }}
        />
      )}
      <div className="search-result__wrapper">{children}</div>

      <Divider
        style={{ marginTop: '3px', marginBottom: '3px', width: '100%' }}
      />
    </div>
  );
}
