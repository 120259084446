import {
  CONNECT_WITH_SSO,
  AppAction,
  AppState,
  SHOW_SECRET,
  DISCONNECT,
  CONNECT_WITH_BAD_CREDENTIALS,
  DISPLAY_ITEM_LIMIT,
  DATA_REFRESH_INTERVAL,
  SCROLL_TO_COMPONENT,
  COLUMN_INFO,
  DIVISION,
  UPDATE_SCHEDULED_ITEM,
  UPDATE_UNSCHEDULED_ITEM,
  WORK_CENTERS,
} from './types';

export const initialState: AppState = {
  secret: '',
  error: false,
  displayItemLimit: 0,
  dataRefreshIntervalMin: 5,
  scrollToComponent: null,
  columnInfo: {
    location: [],
    workcenter: [],
  },
  updateItemFlag: {
    scheduled: false,
    unscheduled: false,
  },
  division: {
    code: '',
    name: '',
  },
  workcenters: [],
};

export function reducer(
  previousState = initialState,
  action: AppAction
): AppState {
  const state = { ...previousState };
  state.error = false;
  switch (action.type) {
    case CONNECT_WITH_SSO: {
      state.user = action.user;
      console.log('connect with sso ok');
      return state;
    }
    case CONNECT_WITH_BAD_CREDENTIALS: {
      state.error = true;
      console.log('connect with sso ok');
      return state;
    }
    case SHOW_SECRET: {
      state.secret = action.secret;
      console.log('set secret', state);

      return state;
    }
    case DISCONNECT: {
      state.secret = '';
      state.user = undefined;
      return state;
    }

    case DISPLAY_ITEM_LIMIT: {
      state.displayItemLimit = action.displayItemLimit;
      return state;
    }

    case DATA_REFRESH_INTERVAL: {
      state.dataRefreshIntervalMin = action.dataRefreshIntervalMin;
      return state;
    }

    case SCROLL_TO_COMPONENT: {
      state.scrollToComponent = action.scrollToComponent;
      return state;
    }

    case COLUMN_INFO: {
      state.columnInfo = action.columnInfo;
      return state;
    }

    case UPDATE_SCHEDULED_ITEM: {
      state.updateItemFlag = {
        ...state.updateItemFlag,
        scheduled: action.updateScheduledItem,
      };
      return state;
    }

    case UPDATE_UNSCHEDULED_ITEM: {
      state.updateItemFlag = {
        ...state.updateItemFlag,
        unscheduled: action.updateUnscheduledItem,
      };
      return state;
    }

    case DIVISION: {
      state.division = action.division;
      return state;
    }

    case WORK_CENTERS: {
      state.workcenters = action.workcenters;
      return state;
    }

    default:
      return previousState;
  }
}
