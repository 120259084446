import React from 'react';
import { Layout } from 'antd';
import Header from '../../components/header/header.component';
import FooterComponent from '../../components/footer/footer.component';
import Dashboard from '../../components/main/catalog.component';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CONST_API_URL } from '../../appconfig';

const { Content } = Layout;
class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // date: new Date(),
      download: false,
      tab: 'Unscheduled',
      location: [{ name: 'North Plant', code: 'North' }],
      locationWorkCenters: [],
      allWorkCenters: [],
      filteredWorkCenters: [],
      latestSync: '',
      navCompany: '',
      navEnvironment: '',
    };
  }

  // updateDate = (data) => {
  //   this.setState({ date: this.processDate(data) });
  // };

  componentDidMount() {
    // this.updateDate(this.state.date);
    // this.updateWorkcenters();
    this.getMostRecentSync();
  }

  updateUnscheduledStandard = (data) => {
    // unscheduled / standard swap
    this.setState({ tab: data.name });
  };

  processDate(date) {
    let SQLFormat = date.toISOString().slice(0, 10);
    return { SQLFormat, date };
  }

  downloadCSV = (data) => {
    // if (data === 'prod') {
    //   if (this.state.type !== 'Dry') {
    //     window.open(
    //       address +
    //         '/item/export/wet?startdate=' +
    //         this.state.date[0].SQLFormat +
    //         '&numofdays=' +
    //         this.state.numofdays +
    //         '&type=' +
    //         this.state.type
    //     );
    //   } else {
    //     window.open(
    //       address +
    //         '/item/export?startdate=' +
    //         this.state.date[0].SQLFormat +
    //         '&numofdays=' +
    //         this.state.numofdays +
    //         '&type=' +
    //         this.state.type
    //     );
    //   }
    // } else {
    //   window.open(
    //     address +
    //       '/item/export/bagging?startdate=' +
    //       this.state.date[0].SQLFormat +
    //       '&numofdays=' +
    //       this.state.numofdays +
    //       '&type=' +
    //       this.state.type
    //   );
    // }
  };
  updateLocation = (locations) => {
    // location = location.name.toUpperCase();
    // const locationNames = locations.map(location => location.name.toUpperCase());

    const filteredWorkCenters = this.state.allWorkCenters.filter((obj) => {
      const recordLocation = obj.workcenter_location; //.toUpperCase();
      return locations.some((location) => location.code === recordLocation);
      //   switch (recordLocation) {
      //     case 'NORTH':
      //       return location === 'NORTH PLANT';

      //     case 'SOUTH':
      //       return location === 'SOUTH PLANT';

      //     default:
      //       return true;
      //   }
    });

    // console.log('updateLocation:', location, filteredWorkCenters);
    this.setState({
      location: locations,
      locationWorkCenters: filteredWorkCenters,
      filteredWorkCenters: filteredWorkCenters,
    });
    // }
  };

  // updateWorkcenters() {
  //   axios.get(address + '/nav/workcenter').then((res) => {
  //     // const data = res.data.map((obj) => obj.workcenter);
  //     this.setState({ allWorkCenters: res.data }, () => {
  //       this.updateLocation(this.state.location);
  //     });
  //   });
  // }

  getMostRecentSync = () => {
    axios.get(CONST_API_URL + '/sync').then((res) => {
      const data = res.data;
      const date = new Date(data.utcdatetime);

      this.setState({
        latestSync: date,
        navCompany: res.data.nav_company_name,
        navEnvironment: res.data.nav_env_type,
      });
      // this.setState({ latestSyncTime: date.toLocaleTimeString() });
      // this.setState({ NAVCompany: data.nav_company_name });
    });
  };

  filterWorkcenters(values) {
    // obj: {name: name}
    if (values.length === 0) {
      this.setState({ filteredWorkCenters: this.state.locationWorkCenters });
    } else {
      const filteredWorkCenters = this.state.locationWorkCenters.filter((obj) =>
        values.some((selectedObj) => selectedObj.name === obj.workcenter)
      );
      this.setState({ filteredWorkCenters: filteredWorkCenters });
    }
  }

  render() {
    return (
      <>
        <Layout style={{ minHeight: '100vh' }}>
          <Header
            // passDate={this.updateDate}
            // download={this.downloadCSV}
            // passTab={this.updateUnscheduledStandard}
            // passLocation={this.updateLocation}
            // locationWorkCenters={this.state.locationWorkCenters}
            // passSelectedWorkcenters={(values) => this.filterWorkcenters(values)}
            latestSync={this.state.latestSync}
            navCompany={this.state.navCompany}
            navEnvironment={this.state.navEnvironment}
          />
          <Layout style={{ height: 0 }}>
            <Layout className="site-layout" style={{ height: '100%' }}>
              <Content style={{ margin: '0 16px' }}>
                <div
                  className="site-layout-background"
                  style={{
                    height: '100%',
                    padding: 0,
                    minHeight: 360,
                    // maxHeight: '89vh',
                  }}
                >
                  <Dashboard
                    DownloadCSV={this.state.download}
                    tab={this.state.tab}
                    // location={this.state.location}
                    // workcenters={this.state.filteredWorkCenters}
                    workcenters={this.props.columnInfo.workcenter}
                    updateLatestSync={this.getMostRecentSync}
                  />
                </div>
              </Content>
            </Layout>
          </Layout>
          <FooterComponent />
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  columnInfo: state.columnInfo,
});
export default connect(mapStateToProps)(withRouter(HomePage));
