import React, { useEffect, useState, useRef } from 'react';
import { RedoOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { store } from '../../redux/store';
import {
  UPDATE_SCHEDULED_ITEM,
  UPDATE_UNSCHEDULED_ITEM,
} from '../../redux/types';
import { Row } from 'antd';
import { Toast } from 'primereact/toast';
import { CONST_API_URL } from '../../appconfig';

const SyncInfoWithButton = ({ latestSyncDate }) => {
  const [syncLoading, setSyncLoading] = useState(false);
  const [latestSyncDateState, setLatestSyncDateState] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    if (latestSyncDate) {
      setLatestSyncDateState(latestSyncDate);
    }
  }, [latestSyncDate]);

  const getMostRecentSyncDatetime = async () => {
    try {
      const res = await axios.get(CONST_API_URL + '/sync');
      if (res.data?.utcdatetime) {
        setLatestSyncDateState(new Date(res.data.utcdatetime));
      }
    } catch (error) {
      console.log('ERROR - getMostRecentSyncDatetime', error);
    }
  };

  const executeSync = () => {
    setSyncLoading(true);

    axios
      .post(`${CONST_API_URL}/sync`)
      .then((res) => {
        if (res.status === 200) {
          store.dispatch({
            type: UPDATE_UNSCHEDULED_ITEM,
            updateUnscheduledItem: true,
          });
          store.dispatch({
            type: UPDATE_SCHEDULED_ITEM,
            updateScheduledItem: true,
          });

          getMostRecentSyncDatetime();
        }
      })
      .catch((error) => {
        if (error.response.status === 503) {
          return toast.current.show({
            severity: 'error',
            summary: 'Server is busy',
            detail:
              error.response.data?.message +
                ' Please refresh your browser or try again later' ||
              'Please try later.',
            life: 3000,
          });
        }
        console.log(`ERROR - executeSync:\n${error}`);
      })
      .finally(() => {
        setSyncLoading(false);
      });
  };
  return (
    <div>
      <Toast ref={toast} />
      <Row style={{ alignItems: 'center' }}>
        {syncLoading ? (
          <>
            <p
              style={{ margin: '0' }}
            >{`Synchronizing... (This takes up to 5 Minutes)`}</p>
            <LoadingOutlined style={{ fontSize: '1.6em', margin: '0 0.4em' }} />
          </>
        ) : (
          <>
            <p style={{ margin: '0' }}>
              Last Sync:{' '}
              {latestSyncDateState &&
                latestSyncDateState.toLocaleDateString() +
                  ' ' +
                  latestSyncDateState.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
            </p>
            <RedoOutlined
              style={{ fontSize: '1.6em', marginLeft: '0.4em' }}
              onClick={executeSync}
            />
          </>
        )}
      </Row>
    </div>
  );
};

export default SyncInfoWithButton;
