export const CONNECT_WITH_BAD_CREDENTIALS = 'connect-with-bad-credentials';
export const CONNECT_WITH_SSO = 'connect-with-sso';
export const DISCONNECT = 'disconnect';
export const SHOW_SECRET = 'show-secret';
export const DISPLAY_ITEM_LIMIT = 'display_item_limit';
export const DATA_REFRESH_INTERVAL = 'data_refresh_interval';
export const SCROLL_TO_COMPONENT = 'scroll_to_component';
export const COLUMN_INFO = 'selected_location';
export const DIVISION = 'division';
export const UPDATE_SCHEDULED_ITEM = 'update_scheduled_item';
export const UPDATE_UNSCHEDULED_ITEM = 'update_unscheduled_item';
export const WORK_CENTERS = 'work_centers';

//-------------------Interface-----------------------
export interface User {
  domain: string;
  name: string;
  displayName: string;
  groups: string[];
  sid: string;
  adUser?: any;
  // mainColumns:[];
  // subColumns:[];
  // itemColumn:[];
}

interface scrollToComponent {
  status: string;
  workcenter?: string;
  index: number;
  itemId: number;
}

interface UpdateItemFlag {
  scheduled?: boolean;
  unscheduled?: boolean;
}

interface Division {
  name: string;
  code: string;
}

interface columnInfo {
  location: object[];
  workcenter: object[];
}

//-------------------Action-----------------------
interface ConnectWithSSOAction {
  type: typeof CONNECT_WITH_SSO;
  user: User;
}

interface ConnectWithCredentialsAction {
  type: typeof CONNECT_WITH_BAD_CREDENTIALS;
}

interface ShowSecretAction {
  type: typeof SHOW_SECRET;
  secret: string;
}

interface DisconnectAction {
  type: typeof DISCONNECT;
}

interface DisplayItemLimitAction {
  type: typeof DISPLAY_ITEM_LIMIT;
  displayItemLimit: number;
}

interface DataRefreshIntervalAction {
  type: typeof DATA_REFRESH_INTERVAL;
  dataRefreshIntervalMin: number;
}

interface ScrollToComponentAction {
  type: typeof SCROLL_TO_COMPONENT;
  scrollToComponent: scrollToComponent;
}

interface ColumnInfoAction {
  type: typeof COLUMN_INFO;
  columnInfo: columnInfo;
}

interface UpdateScheduledItemAction {
  type: typeof UPDATE_SCHEDULED_ITEM;
  updateScheduledItem: boolean;
}

interface UpdateUnscheduledItemAction {
  type: typeof UPDATE_UNSCHEDULED_ITEM;
  updateUnscheduledItem: boolean;
}

interface DivisionAction {
  type: typeof DIVISION;
  division: Division;
}

interface WorkcentersAction {
  type: typeof WORK_CENTERS;
  workcenters: object[];
}

export type AppAction =
  | ConnectWithSSOAction
  | ConnectWithCredentialsAction
  | ShowSecretAction
  | DisconnectAction
  | DisplayItemLimitAction
  | DataRefreshIntervalAction
  | ScrollToComponentAction
  | ColumnInfoAction
  | UpdateScheduledItemAction
  | UpdateUnscheduledItemAction
  | DivisionAction
  | WorkcentersAction;

//-------------------State-----------------------
export interface UserState {
  user?: User;
}

export interface SecretState {
  secret: string;
}

export interface ErrorState {
  error: boolean;
}

export interface DisplayItemLimitState {
  displayItemLimit: number;
}

export interface DataRefreshIntervalState {
  dataRefreshIntervalMin: number;
}

export interface ScrollToComponentState {
  scrollToComponent: scrollToComponent | null;
}

export interface ColumnInfoState {
  columnInfo: columnInfo;
}

export interface UpdateItemState {
  updateItemFlag: UpdateItemFlag;
}

export interface DivisionState {
  division?: Division;
}

export interface WorkcentersState {
  workcenters: object[];
}

export interface AppState
  extends UserState,
    SecretState,
    ErrorState,
    DisplayItemLimitState,
    DataRefreshIntervalState,
    ScrollToComponentState,
    ColumnInfoState,
    DivisionState,
    UpdateItemState,
    WorkcentersState {}

export interface ConnectDispatch {
  disconnect: () => void;
}
