import { Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { CONST_API_URL } from '../../appconfig';

const ImportDrawingForm = () => {
  const [fileList, setFileList] = useState([]);
  const uploaderprops = {
    accept: '.pdf',
    name: 'file',
    action: CONST_API_URL + '/drawing',
    multiple: true,

    beforeUpload: (file) => {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        message.error(`${file.name} is not a pdf file`);
      }
      return isPDF || Upload.LIST_IGNORE;
    },

    onRemove(file) {
      setFileList(fileList.filter((item) => item.name !== file.name));
    },

    onChange: (info) => {
      let fileList = [...info.fileList];
      setFileList(fileList);
    },
  };

  return (
    <>
      <ul>
        <li>The PDF format is supported only</li>
        <li>
          The format of file name must be {`"{Customer code}_{Item No}"`}
          <br />
          <span style={{ marginLeft: '1em' }}>ex) ABRAM_T160</span>
        </li>
        <li>
          Existing drawing file will be overwritten if the different file with
          same file name is uploaded
        </li>
      </ul>
      <Upload {...uploaderprops} fileList={fileList}>
        <Button
          icon={<UploadOutlined />}
          className="mb-2"
          size="large"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Upload PDF Drawings
        </Button>
      </Upload>
    </>
  );
};

export default ImportDrawingForm;
