import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Row, Col, Form } from 'antd';
import axios from 'axios';
import { CONST_API_URL, EMAIL_RECIPIENT } from '../../appconfig';

const HelpButton = ({ user }) => {
  const CONST_REQUESTS = [
    'Data loading is not over',
    'Wrong data is populated',
    'Cannot perform drag & drop',
    'Abnormal view',
    'Others',
  ];
  const [reasonSelected, setReasonSelected] = useState(null);
  const [detailMessage, setDetailMessage] = useState('');
  const [dialogVisible, setDialogVisible] = useState(false);
  //   const [screenshotData, setScreenshotData] = useState(null);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);

  const toast = useRef(null);
  const [form] = Form.useForm();

  const sendEmail = (values) => {
    setSendEmailLoading(true);
    const client = user.fullName;
    const reason = values.reason;
    const detail = values.detailMessage || '';
    const mail = `Client: ${client}\nReason: ${reason}\nDetail Message:\n${detail}`;

    const parameters = {
      mail: mail,
      recipient: EMAIL_RECIPIENT,
      //   base64Screenshot: screenshotData,
    };

    axios
      .post(`${CONST_API_URL}/sendEmail`, parameters)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Requested',
          detail: `An email has been sent to support to troubleshoot the issue`,
          life: 3000,
        });
        setReasonSelected(null);
        setDetailMessage('');
        setDialogVisible(false);
        form.setFieldsValue({
          reason: null,
          detailMessage: '',
        });
      })
      .catch((error) => {
        console.log('ERROR - SEND EMAIL:', error);
        toast.current.show({
          severity: 'error',
          summary: 'Request failed',
          detail: error.message || `Unexpected error occurred`,
          life: 3000,
        });
      })
      .finally(() => setSendEmailLoading(false));
  };

  const onFinish = (values, history) => {
    console.log('TEST:', values);
    sendEmail(values);
  };

  const renderDialogHeader = () => {
    return (
      <>
        <Row>
          <Col style={{ flex: '1' }}>
            <h2>Help Request</h2>
          </Col>
        </Row>
      </>
    );
  };
  const renderDialogFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          className="p-button-danger"
          onClick={() => setDialogVisible(false)}
        />
        <Button
          label="Send"
          onClick={() => form.submit()}
          //   type="submit"
          loading={sendEmailLoading}
        />
      </>
    );
  };
  return (
    <>
      <Button
        label="Help"
        style={{ backgroundColor: '#EE2023', borderColor: '#EE2023' }}
        onClick={() => setDialogVisible(true)}
      />

      <Toast ref={toast} />
      {/* 
      <Modal visible={true}>
        <Form onFinish={onFinish} name="basic"></Form>
      </Modal> */}

      <Dialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        // modal={true}
        draggable={false}
        // dismissableMask={true}
        header={renderDialogHeader}
        footer={renderDialogFooter}
        style={{
          minWidth: '40vw',
          width: 'fit-content',
        }}
      >
        <Form onFinish={onFinish} form={form}>
          <h5>Reason for request:</h5>
          <Form.Item
            name="reason"
            rules={[{ required: true, message: 'Please select a reason' }]}
            // label="Reason for request"
          >
            <Dropdown
              value={reasonSelected}
              options={CONST_REQUESTS}
              placeholder="Select a reason"
              onChange={(e) => setReasonSelected(e.value)}
              required={true}
            />
            {/* <Select
              value={reasonSelected}
              // options={CONST_REQUESTS}
              placeholder="Select a reason"
              onChange={(e) => setReasonSelected(e.value)}
              // required={true}
              
            >
              {CONST_REQUESTS.map((reason) => (
                <Select.Option value={reason} key={reason}>
                  {reason}
                </Select.Option>
              ))}
            </Select> */}
          </Form.Item>
          <h5>
            Detail Message:{' '}
            <span style={{ color: 'gray', fontSize: '0.8em' }}>(Optional)</span>
          </h5>
          {/* <Row> */}
          <Form.Item name="detailMessage">
            <InputTextarea
              value={detailMessage}
              onChange={(e) => setDetailMessage(e.target.value)}
              style={{ width: '100%' }}
              rows={5}
            />
          </Form.Item>
          {/* </Row>  */}
          {/* <h5>Reason for request</h5>
          <Row style={{ marginBottom: '1rem' }}>
            <Form.Item name="reason" rules={[{ required: true }]} label="a">
              <AntdDropdown
                value={reasonSelected}
                options={CONST_REQUESTS}
                placeholder="Select a reason"
                onChange={(e) => setReasonSelected(e.value)}
                required={true}
              />
            </Form.Item>
          </Row>

          <h5>
            Detail Message{' '}
            <span style={{ color: 'gray', fontSize: '0.8em' }}>(Optional)</span>
          </h5>
          <Row>
            <Form.Item name="detailMessage" label="b">
              <Input.TextArea
                value={detailMessage}
                onChange={(e) => setDetailMessage(e.target.value)}
                style={{ width: '100%' }}
                rows={5}
              />
            </Form.Item>
          </Row> */}
        </Form>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(HelpButton);
