import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Spin } from 'antd';
import { Column as PrimeColumn } from 'primereact/column';
import axios from 'axios';
import { CONST_API_URL } from '../../appconfig';

const convertHoursToTimeFormat = (hours) => {
  let minutes = hours % 1;
  hours -= minutes;
  minutes = Math.round(minutes * 60);
  minutes = minutes >= 60 ? 59 : minutes;
  return `${hours}h ${minutes}m`;

  // let minutes = hours % 1;
  // hours -= minutes;
  // minutes = minutes * 60;
  // let seconds = minutes % 1;
  // minutes -= seconds;
  // seconds = Math.round(seconds * 60);

  // if (seconds >= 60) {
  //   seconds = 59;
  // }

  // hours = String(hours).padStart(2, '0');
  // minutes = String(minutes).padStart(2, '0');
  // seconds = String(seconds).padStart(2, '0');
  // return `${hours}:${minutes}:${seconds}`;
};

const PostHistoryDialog = ({ visible, onHide, dialogData }) => {
  const targetDialogData = Array.isArray(dialogData)
    ? dialogData[0]
    : dialogData;
  //   console.log('dialogData:', targetDialogData);

  const [tableData, setTableData] = useState(null);
  const [loadingTableData, setLoadingTableData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingTableData(true);
        const response = await axios.get(
          CONST_API_URL + '/nav/capacityledgerentries',
          {
            params: {
              order_no: targetDialogData.order_no,
              addHspopNo: true,
            },
          }
        );

        if (response?.data?.value) {
          setTableData(response.data.value);
        }
      } catch (error) {
        console.log('Error - fetch capacity ledger entries', error);
      } finally {
        setLoadingTableData(false);
      }
    };

    if (targetDialogData && visible) {
      fetchData();
    }
  }, [targetDialogData, visible]);

  return (
    <Dialog
      visible={visible}
      position="top"
      style={{
        // width: '70vw',
        backgroundColor: 'white',
        zIndex: 61,
      }}
      modal={true}
      onHide={onHide}
      draggable={false}
      dismissableMask={true}
      contentStyle={{ height: '100%', padding: '0px' }}
      header={
        <>
          <h1 style={{ marginBottom: '1rem' }}>Post History</h1>
          <h3>
            MO: {targetDialogData?.order_no} ({targetDialogData?.item_no})
          </h3>
          {/* <p>
            Designated Work Center:{' '}
            <span style={{ fontSize: '1.2em' }}>
              {targetDialogData?.operation_workcenter}
            </span>
          </p> */}
        </>
      }
      //   footer={
      //     <div>
      //       <Button
      //         label="Close"
      //         icon="pi pi-times"
      //         onClick={onHide}
      //         className="p-button-text"
      //       />
      //     </div>
      //   }
    >
      <Spin spinning={loadingTableData}>
        <DataTable
          value={tableData}
          style={{ marginBottom: '1.5rem' }}
          paginator
          rows={10}
          autoLayout
        >
          <PrimeColumn
            field="Posting_Date"
            header="Posting Date" // format: 08/31/2022
            sortable
          />
          <PrimeColumn field="Work_Center_No" header="Workcenter No" />
          {/* <PrimeColumn field="Operation_No" header="Operation No" /> */}
          <PrimeColumn field="HSPOP_No" header="HSPOP No" />
          <PrimeColumn field="Description" header="Description" />
          <PrimeColumn
            header="Setup Time"
            body={(rowData) => {
              const data = rowData.Setup_Time;
              return convertHoursToTimeFormat(data);
            }}
          />
          <PrimeColumn
            header="Run Time"
            body={(rowData) => {
              const data = rowData.Run_Time;
              return convertHoursToTimeFormat(data);
            }}
          />
          <PrimeColumn field="Output_Quantity" header="Output Quantity" />
        </DataTable>
      </Spin>
    </Dialog>
  );
};

export default PostHistoryDialog;
