import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Drawer, Row } from 'antd';
import { Dropdown as PrimeDropDown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { store } from '../../redux/store';
import { DATA_REFRESH_INTERVAL } from '../../redux/types';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ImportDrawingForm from './importdrawingform.component';
import StorageIcon from '@material-ui/icons/Storage';
import { CONST_API_URL, DEV_MODE } from '../../appconfig';
import WorkCenterSetupForm from './workcentersetupform.component';

const Settings = ({
  dataRefreshIntervalMin,
  navCompany,
  navEnvironment,
  division,
  reloadWorkCenters,
}) => {
  const [dataRefreshIntervalSelected, setDataRefreshIntervalSelected] =
    useState(null);
  const [dataRefreshIntervalOptions, setDataRefreshIntervalOptions] = useState(
    []
  );
  const [importDrawingDialogVisible, setImportDrawingDialogVisible] =
    useState(false);
  const [workcenterDialogVisible, setWorkcenterDialogVisible] = useState(false);

  useEffect(() => {
    getDropdown('dataRefreshInterval');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getDropdown(dropdown) {
    axios
      .get(CONST_API_URL + '/config/dropdown', { params: { dropdown } })
      .then((res) => {
        const data = res.data;

        if (dropdown === 'dataRefreshInterval') {
          setDataRefreshIntervalOptions(data);
          if (!Number.isNaN(dataRefreshIntervalMin)) {
            const dataObj = data.find(
              (obj) => Number(obj.code) === dataRefreshIntervalMin
            );
            if (dataObj) {
              setDataRefreshIntervalSelected(dataObj);
            }
          }
        }
      });
  }

  async function onClickExportProductionSchedule(division) {
    window.open(
      `${CONST_API_URL}/export/prodschedule?localTimezoneOffset=${new Date().getTimezoneOffset()}&division=${division}`
    );
  }

  return (
    <>
      <div style={{ padding: '0 0.8rem' }}>
        <Row
          style={{
            alignItems: 'center',
            marginBottom: '1rem',
            lineHeight: '38px',
          }}
        >
          <label style={{ margin: '0 1rem 0 0', fontWeight: '500' }}>
            <StorageIcon style={{ marginRight: '0.4rem' }} />
            NAV Company:
          </label>
          {DEV_MODE ? (
            <p className="text-danger">
              Env: <b>{navEnvironment}</b> Company: <b>{navCompany}</b>
            </p>
          ) : (
            <p>{navCompany}</p>
          )}
        </Row>

        <Row style={{ alignItems: 'center', marginBottom: '1rem' }}>
          <label style={{ margin: '0 1rem 0 0', fontWeight: '500' }}>
            <RefreshIcon style={{ marginRight: '0.4rem' }} />
            Refresh: (every)
          </label>
          <PrimeDropDown
            value={dataRefreshIntervalSelected}
            options={dataRefreshIntervalOptions}
            optionLabel="name"
            onChange={(e) => {
              setDataRefreshIntervalSelected(e.value);
              store.dispatch({
                type: DATA_REFRESH_INTERVAL,
                dataRefreshIntervalMin: Number(e.value.code) || 5,
              });
            }}
          />
        </Row>
        <Row style={{ alignItems: 'center', marginBottom: '1rem' }}>
          <label style={{ margin: '0 1rem 0 0', fontWeight: '500' }}>
            <ArrowUpwardIcon style={{ marginRight: '0.4rem' }} />
            Import PDF drawing
          </label>
          <Button
            label="Import"
            className="p-button-outlined p-button-danger"
            onClick={() => setImportDrawingDialogVisible(true)}
          />
        </Row>

        <Row style={{ alignItems: 'center', marginBottom: '1rem' }}>
          <label style={{ margin: '0 1rem 0 0', fontWeight: '500' }}>
            <ArrowDownwardIcon style={{ marginRight: '0.4rem' }} />
            Export Production Schedule
          </label>
          <Button
            label="Download"
            className="p-button-outlined p-button-danger"
            onClick={() => onClickExportProductionSchedule(division?.code)}
          />
        </Row>

        <Row style={{ alignItems: 'center', marginBottom: '1rem' }}>
          <label style={{ margin: '0 1rem 0 0', fontWeight: '500' }}>
            <ViewColumnIcon style={{ marginRight: '0.4rem' }} />
            Sort Work Center
          </label>
          <Button
            label="Change"
            className="p-button-outlined p-button-danger"
            onClick={() => setWorkcenterDialogVisible(true)}
            {...(!!!division.code && { disabled: true })}
          />
        </Row>
      </div>

      <Drawer
        visible={importDrawingDialogVisible}
        title={'Import PDF Drawing'}
        width={'30vw'}
        onClose={() => setImportDrawingDialogVisible(false)}
      >
        <ImportDrawingForm />
      </Drawer>

      <Drawer
        visible={workcenterDialogVisible}
        title={'Work Center Sort Setup'}
        width={'50vw'}
        onClose={() => setWorkcenterDialogVisible(false)}
      >
        <WorkCenterSetupForm
          onCancel={() => setWorkcenterDialogVisible(false)}
          reloadWorkCenters={() => reloadWorkCenters?.()}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  dataRefreshIntervalMin: state.dataRefreshIntervalMin,
  division: state.division,
});
export default connect(mapStateToProps)(withRouter(Settings));
