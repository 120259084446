import React from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import Homepage from './pages/homepage/homepage.component';
import Loginpage from './pages/login/login.page';

import ResetPasswordPage from './pages/resetpasswordpage/reset-password.page';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  user: state.user,
});

function App({ history, user }) {
  var isUserThere = false;
  if (user) {
    const expiryTime = localStorage.getItem('user-scheduler-Haven');
    const today = new Date();

    if (
      new Date(expiryTime) <= new Date(today) ||
      expiryTime === '' ||
      expiryTime == null
    ) {
      localStorage.setItem('user-scheduler-Haven', '');
      isUserThere = false;
    } else {
      isUserThere = true;
    }
  } else {
    isUserThere = false;
  }

  return (
    <div>
      <Switch>
        <Route
          exact
          path={['/', '/home']}
          render={() =>
            isUserThere ? (
              <Homepage />
            ) : (
              <Redirect to={{ pathname: '/login' }} />
            )
          }
        />
        <Route path={['/login', '/register', '/request-reset']}>
          {isUserThere ? <Redirect to={{ pathname: '/' }} /> : <Loginpage />}
        </Route>
        <Route path="/ResetPassword" render={() => <ResetPasswordPage />} />
      </Switch>
    </div>
  );
}

export default withRouter(connect(mapStateToProps)(App));
