export const DEV_MODE = process.env.NODE_ENV === 'development';
export const CONST_API_URL =
  process.env.REACT_APP_API_URL || 'no-valid-env-found';
// export const CONST_API_URL =
//   DEV_MODE === ON
//     ? 'http://192.168.80.73:1338'
//     : // 'http://localhost:1338'
//       'https://scheduler-api.havensteel.alpacasys.com';

export const EMAIL_RECIPIENT = process.env.REACT_APP_EMAIL_RECIPIENT; //'jilee@alpacasys.com';

// export const CONST_VERSION = '1.1.0'; => Managed in package.json
