import React from 'react';

const Disabled = ({ disabled, children, style }) => {
  if (disabled) {
    return (
      <div style={{ ...style, opacity: 0.4, pointerEvents: 'none' }} disabled>
        {children}
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default Disabled;
