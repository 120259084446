import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input, Form, Space } from 'antd';
import './request-reset-form.styles.css';

const validateMessages = {
  required: 'Email is required to reset password!',
  types: { email: 'Not a valid email format!' },
};
const email = [{ required: true, type: 'email' }];

/* IMPORTANT PROPS
	onFinish = onFinish method of the form, 
	btnLoading = handles the button loading status
*/
class RequestResetForm extends React.Component {
  form = React.createRef();

  render() {
    const { btnLoading } = this.props;
    return (
      <div id="request-reset-form">
        <h3 className="login-title">Reset Password</h3>
        <p>Please enter your email to request a password reset</p>
        <Form
          ref={this.form}
          validateMessages={validateMessages}
          requiredMark={false}
          onFinish={this.props.onFinish}
        >
          <Form.Item name="email" rules={email}>
            <Input placeholder="Email Address" />
          </Form.Item>
          <Form.Item className="space-up">
            <Space>
              <Button
                htmlType="submit"
                className="primary-btn"
                loading={btnLoading}
              >
                Reset password
              </Button>
              <Button
                htmlType="button"
                className="borderless-btn"
                onClick={this.handleBack}
              >
                Back
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    );
  }

  handleBack = () => {
    this.props.history.push('/login');
  };
}

export default withRouter(RequestResetForm);
