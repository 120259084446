import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Spin } from 'antd';
import { Column as PrimeColumn } from 'primereact/column';
import axios from 'axios';
import moment from 'moment';
import { CONST_API_URL } from '../../appconfig';

const CardMovementHistoryDialog = ({ visible, onHide, dialogData }) => {
  const targetDialogData = Array.isArray(dialogData)
    ? dialogData[0]
    : dialogData;
  //   console.log('dialogData:', targetDialogData);

  const [tableData, setTableData] = useState(null);
  const [loadingTableData, setLoadingTableData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingTableData(true);
        const response = await axios.get(CONST_API_URL + '/movementhistory', {
          params: {
            order_no: targetDialogData.order_no,
          },
        });

        if (response?.data) {
          handleData(response.data);
        }
      } catch (error) {
        console.log('Error - fetch card movement history', error);
      } finally {
        setLoadingTableData(false);
      }
    };

    if (targetDialogData && visible) {
      fetchData();
    }
  }, [targetDialogData, visible]);

  const handleData = (data) => {
    const structuredData = data.map((obj) => {
      const utcDateTime = obj.created_utc_datetime;
      const localDateTime = moment(utcDateTime).format('YYYY-MM-DD HH:mm:ss');
      return {
        ...obj,
        localDateTime,
      };
    });
    setTableData(structuredData);
  };

  return (
    <Dialog
      visible={visible}
      position="top"
      style={{
        width: '70vw',
        backgroundColor: 'white',
        zIndex: 61,
      }}
      modal={true}
      onHide={onHide}
      draggable={false}
      dismissableMask={true}
      contentStyle={{ height: '100%', padding: '0px' }}
      header={
        <>
          <h1 style={{ marginBottom: '1rem' }}>Card Movement History</h1>
          <h3>
            MO: {targetDialogData?.order_no} ({targetDialogData?.item_no})
          </h3>
        </>
      }
    >
      <Spin spinning={loadingTableData}>
        <DataTable
          value={tableData}
          style={{ marginBottom: '1.5rem' }}
          paginator
          rows={10}
          autoLayout
        >
          <PrimeColumn field="workcenter_from" header="From Work Center" />
          <PrimeColumn field="workcenter_to" header="To Work Center" />
          <PrimeColumn
            // field="row_no_from"
            header="From Index"
            body={(rowData) => rowData.row_no_from + 1} // Display index starting from 1 instead from 0
          />
          <PrimeColumn
            // field="row_no_to"
            header="To Index"
            body={(rowData) => rowData.row_no_to + 1} // Display index starting from 1 instead from 0
          />
          <PrimeColumn field="created_by_email" header="Moved by" />
          <PrimeColumn field="localDateTime" header="Date/Time" sortable />
        </DataTable>
      </Spin>
    </Dialog>
  );
};

export default CardMovementHistoryDialog;
